import * as React from "react";
import { Router } from "react-router-dom";
import {
	createMemoryHistory,
	createBrowserHistory,
	History,
} from "history";

interface IDynamicRouterProps {
	useUrls: boolean;
	syncWithStore: (history: History) => void;
}

/**
 * The DynamicRouter provides a react-router instance that
 * that is coupled to our RoutingStore.
 * the RoutingStore can then be used to interact with the
 * browser history object
 *
 * @export
 * @class DynamicRouter
 * @extends {React.Component<IDynamicRouterProps, {}>}
 */
export default class DynamicRouter extends React.Component<IDynamicRouterProps, {}> {
	private history: History;

	constructor(props: IDynamicRouterProps) {
		super(props);

		if (props.useUrls) {
			this.history = createBrowserHistory();
		} else {
			this.history = createMemoryHistory();
		}

		props.syncWithStore(this.history);
	}

	render() {
		return (
			<Router history={this.history}>
				{this.props.children}
			</Router>
		);
	}
}
