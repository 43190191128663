// tslint:disable:max-line-length

import { IBundle } from "../lib/interfaces";

export default {
	login: {
		emailPlaceholder: "E-Mail Adresse",
		loginButton: "Einloggen",
		checkMail: "Prüfen Sie Ihren Posteingang!",
		waitForLogin: "Sie werden eingeloggt...",

		landing: {
			success: "Sie werden eingeloggt. Dieses Fenster kann jetzt geschlossen werden.",
			failed: "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut!",
		},

		nextStep: "Weiter",
		back: "Zurück",
		refresh: "Neu laden",
		loadingCaptcha: "Captcha wird geladen..."
	},

	navbar: {
		logout: "Ausloggen",
		orgDropdown: "Organisationen",
	},

	errors: {
		title: "Ups!",
		message: "Tut uns leid! Hier ist wohl etwas schief gelaufen. :(",
		reloadBtn: "Seite neu laden",

		session_invalid: {
			title: "Sessionfehler",
			message: "Ihre Session ist leider ungültig. Bitte laden Sie die App neu und versuchen Sie es noch einmal.",
		},
	},

	imprintLink: "Impressum",

	loading: "Daten werden geladen...",

	clientCard: {
		overview: "Übersicht der letzten 3 Monate",
		last3Months: "Letzte 3 Monate",
		topProjects: "Top Projekte der letzten %s Monate",
		emptyTopProjects: "Noch keine Zeiten in den letzten %s Monaten eingetragen",
		detailBtn: "Details",
	},

	clientDetails: {
		topUsers: "Top User",
		topProjects: "Top Projekte",
		lastTwelveMonths: "Letztes Jahr",
		header: "Übersicht: %s",
		filters: {
			month: "Monat",
			selectMonth: "Monat auswählen",
			resetMonth: "Auf aktuellen Monat setzen",
			resetSelect: "Alle auswählen",
			projects: "Projekt(e)",
			selectProjects: "Projekt(e) auswählen",
			users: "User",
			selectUsers: "User auswählen",
			roles: "Rolle(n)",
			selectRoles: "Rolle(n) auswählen",
			all: "Alle",
		},
		charts: {
			"hours": "Stunden"
		},
		table: {
			headings: {
				project: "Projekt",
				role: "Rolle",
				hours: "Stunden",
				sum: "Gesamt",
			},
			empty: "Für den aktuellen Filter sind noch keine Daten vorhanden.",
			emptyChart: "Für den aktuellen Filter ist kein Chart verfügbar.",
		},
		reportBtn: "PDF herunterladen",
	},

	languages: {
		german: "Deutsch",
		english: "Englisch"
	}
} as IBundle;
