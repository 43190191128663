import * as React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import { InvalidCodePathError } from "../../../lib/errors";
import stores from "../../../stores";
import { LOGIN_STATE } from "../../../lib/enums";

import InputForm from "./InputForm";
import MailSent from "./MailSent";
import LoggingIn from "./LoggingIn";

@observer
export default class InnerView extends React.Component<{}, {}> {
	render() {
		return (
			<div className="inner-view">
				{this.renderInnerPart()}
			</div>
		);
	}

	renderInnerPart() {
		const { loginState } = stores.userInterface;

		switch (loginState) {
			case LOGIN_STATE.INITIAL:
			case LOGIN_STATE.MAIL_SENDING:
				return <InputForm />;

			case LOGIN_STATE.MAIL_SENT:
				return <MailSent />;

			case LOGIN_STATE.LINK_CLICKED:
				return <LoggingIn />;

			case LOGIN_STATE.LOGGED_IN:
				return <Redirect to="/" />;

			default: throw new InvalidCodePathError();
		}
	}
}
