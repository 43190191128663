import * as moment from "moment";

// import * as logger from "./logger";
import stores from "../stores";

/**
 * Save a stringified representation of `value` into localStorage under `key`
 *
 * @export
 * @param {string} key localStorage-Key
 * @param {*} value data
 */
export function save(key: string, value: any) {
	localStorage.setItem(`${ stores.config.localStoragePrefix }:${ key }`, JSON.stringify(value));
}

/**
 * Load data and parse as T from localStorage under `key`
 *
 * @export
 * @template T the type to "cast" the return value to
 * @template any default type
 * @param {string} key localStorage-Key
 * @returns {T} loaded data
 */
export function load<T = any>(key: string): T {
	return JSON.parse(localStorage.getItem(`${ stores.config.localStoragePrefix }:${ key }`) || "null") as T;
}

/**
 * Return a promise that resolves after `millis` milliseconds
 *
 * @export
 * @param {number} millis the amount of ms to 'sleep'
 * @returns {Promise<void>}
 */
export function sleep(millis: number) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, millis);
	});
}

/**
 * Delete a cookie by name
 *
 * @export
 * @param name cookie name
 * @param domain domain name
 * @param path cookie path
 */
export function clearCookie(name: string = "token", domain: string = document.domain, path: string = "/api") {
	document.cookie = name + "=; expires=" + +new Date + "; domain=" + domain + "; path=" + path;
}

/**
 * Get the date (as number; in form YYMMDD) of the first day
 * of the Xth previous month before the current
 * e.g: x = 2; current: December, prev: November, 2nd-pred: October
 *
 * @export
 * @returns {number} parsed date as number
 */
export function getFirstDayXMonthsAgo(x: number = 2) {
	const dayString = moment().startOf("month").subtract(x, "months").format("YYMMDD");
	return parseInt(dayString, 10);
}

/**
 * Get the date object of the first day
 * of the Xth previous month before the current
 * e.g: x = 2; current: December, prev: November, 2nd-pred: October
 *
 * @export
 * @returns {number} parsed date as number
 */
export function getFirstDateXMonthsAgo(x: number = 2) {
	const date = moment().startOf("month").subtract(x, "months").toDate();
	return date;
}

/**
 * Get the date (as number; in form YYMMDD) of the last day
 * of the Xth previous month before the current
 * e.g: x = 2; current: December, prev: November, 2nd-pred: October
 *
 * @export
 * @returns {number} parsed date as number
 */
export function getLastDayXMonthsAgo(x: number = 2) {
	const dayString = moment().endOf("month").subtract(x, "months").format("YYMMDD");
	return parseInt(dayString, 10);
}

export function getDaysSinceFirstDayXMonthsAgo(x: number = 2) {
	return moment().diff(moment().startOf("month").subtract(x, "months"), "days");
}

export function convertApiToHeatmapDate(apiDate: number|string) {
	const parsed = moment(String(apiDate), "YYMMDD");
	const result = parsed.format("YYYY-MM-DD");
	return result;
}

export function convertHeatmapDateToTs(heatmapDate: string) {
	const parsed = moment(heatmapDate, "YYYY-MM-DD");
	return parsed.unix();
}

/**
 * converts an api-datestring to a regular secondtimestamp
 *
 * @export
 * @param {(number|string)} apiDate
 * @returns {number}
 */
export function convertApiDateToTs(apiDate: number|string): number {
	return moment(String(apiDate), "YYMMDD").unix();
}

/**
 * converts a regular secondtimestamp to an a yymm number
 *
 * @export
 * @param {(number|string)} apiDate
 * @returns {number}
 */
export function convertTsToYYMM(ts: number): number {
	const date = new Date(ts);
	const mm = date.getMonth() + 1;
	const yy = date.getFullYear() - 2000;
	return yy * 100 + mm;
}

/**
 * Scale a value from an input to an output range
 *
 * @export
 * @param {number} value the value to scale
 * @param {number} inputMin smallest value in the input range
 * @param {number} inputMax biggest valie in the input range
 * @param {number} scaledMin smallest value in the output range
 * @param {number} scaledMax biggest value in the output range
 * @returns {number} the scaled value
 */
export function scaleValue(
	value: number,
	inputMin: number,
	inputMax: number,
	scaledMin: number,
	scaledMax: number) {

	return (scaledMax - scaledMin) * (value - inputMin) / (inputMax - inputMin) + scaledMin;
}

/**
 * get (localized) month name from a js date
 *
 * @export
 * @param {Date} date
 * @returns {string}
 */
export function getMonthFromDate(date: Date): string {
	return moment(date).format("MMMM");
}

/**
 * get first day of current month as a number in the form of "YYMMDD"
 *
 * @export
 * @returns {number} first day of current month
 */
export function getfirstDayOfMonth(): number {
	return parseInt(moment().startOf("month").format("YYMMDD"), 10);
}

/**
 * * get the last 3 month names as string array
 *
 * eg when it is february you get ["December", "January", "February"]
 *
 * @export
 * @returns {[string, string, string]} last 3 month names
 */
export function getLast3MonthNames(): [string, string, string] {
	const current = moment().startOf("month").format("MMMM");
	const previous = moment().subtract(1, "month").startOf("month").format("MMMM");
	const previous2n = moment().subtract(2, "month").startOf("month").format("MMMM");
	return [previous2n, previous, current];
}

/**
 * * get MM.YY for xth previous month
 *
 * @export
 * @returns {string} "MM.YY"
 */
export function getXthPreviousMonthYear(xth: number): string {
	return moment().subtract(xth, "month").startOf("month").format("MM.YY");
}

/**
 * Get 4 random hexadecimal digits
 *
 * @export
 * @returns {string}
 */
export function hex4Digits(): string {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
}

/**
 * get a random (insecure) guid string
 *
 * @export
 * @returns {string}
 */
export function guid(): string {
	return hex4Digits() + hex4Digits() + "-"
		+ hex4Digits() + "-"
		+ hex4Digits() + "-"
		+ hex4Digits() + "-"
		+ hex4Digits() + hex4Digits() + hex4Digits();
}

/**
 * get the count of days in the specified month
 *
 * @export
 * @param {(number|string)} shortYear
 * @param {(number|string)} month
 * @returns {number}
 */
export function getMonthDayCount(shortYear: number|string, month: number|string): number {
	return moment(shortYear + "" + month, "YYMM").daysInMonth();
}

export function secs2Hours(seconds: number|string) {
	let value: number;

	if (typeof seconds === "string") {
		value = parseInt(seconds, 10);
		if (isNaN(value)) {
			throw new Error("EINVALIDNUMBERSTRING");
		}
	} else {
		value = seconds;
	}

	return Math.round((value / 3600) * 100) / 100;
}

export function secs2HoursFixed(seconds: number|string, fractionDigits: number = 2) {
	return parseFloat(secs2Hours(seconds).toFixed(fractionDigits));
}

export function getMMMMYYYY() {
	return moment().format("MMMM YYYY");
}

export function nukeCookie() {
	clearCookie("token");
}

export function getChartLabelFromMMYY(MMYY: string) {
	const date = moment(MMYY.replace(".", ""), "MMYY");

	if (date.isValid()) {
		return `${date.format("MMM")} ${date.format("YYYY")}`;
	} else {
		return "";
	}
}
