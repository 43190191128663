import { types, getParent } from "mobx-state-tree";

import * as logger from "../../lib/logger";
import { Org, IOrg } from "./Org";
import { IDataModel } from "./Data";
import { Client, IClient } from "./Client";
import { IObservableArray } from "mobx/lib/types/observablearray";
import { ITimeEntry } from "./TimeEntry";
import { getfirstDayOfMonth } from "../../lib/utils";
import { byAlpha } from "../../lib/sorting";
import { IFilter } from "./Filter";
import { IUser } from "./User";
import { IRole } from "./Role";
import { getFirstDayXMonthsAgo } from "../../lib/utils";

export interface IProject {
	id: number;
	name: string;
	oid: IOrg;
	cid: IClient;
	pid: number;
	hidden: boolean;

	subProjects: IObservableArray<IProject>;
	subProjectCount: number;
	times: IObservableArray<ITimeEntry>;
	timeCount: number;
	seconds: number;
	totalSeconds: number;
	totalSecondsInCurrentMonth: number;
	totalSecondsInLastThreeMonths: number;
	subProjectsByAlpha: IObservableArray<IProject>;
	subProjectsByFilter: (filter: IFilter) => IProject[];
	subProjectsByFilterAndAlpha: (filter: IFilter) => IProject[];
	usersByFilter: (filter: IFilter) => IUser[];
	rolesByFilter: (filter: IFilter) => IRole[];
	secondsByFilter: (filter: IFilter) => number;
	totalSecondsByFilter: (filter: IFilter) => number;
	hasTime: (time: ITimeEntry) => boolean;
}

export const Project = types.model("Project", {
	id: types.identifierNumber,
	name: types.string,
	oid: types.late(() => types.reference(Org)),
	cid: types.late(() => types.reference(Client)),
	pid: types.number,
	hidden: types.boolean,
})
.views(self => ({
	get subProjects() {
		const data: IDataModel = getParent(self, 2);
		const result: any = data.projects.filter((project: any) => project.pid === self.id);
		return result;
	},

	get times() {
		return (self.cid.times as ITimeEntry[]).filter((time: ITimeEntry) => time.pid === self);
	},
}))
.views(self => ({
	get seconds(): number {
		return self.times.reduce((acc, current) => acc + current.d, 0);
	},

	get totalTimes(): ITimeEntry[] {
		return self.times.concat(
			...self.subProjects.map((subProject: any) => subProject.totalTimes)
		);
	},
}))
.views(self => ({
	get subProjectCount() {
		return self.subProjects.length;
	},

	get timeCount() {
		return self.times.length;
	},

	get totalSeconds(): number {
		const subSeconds: number = self.subProjects.reduce(
			(total: any, proj: any) => total + proj.totalSeconds, 0);
		return self.seconds + subSeconds;
	},

	get totalSecondsInCurrentMonth(): number {
		const startOfMonth = getfirstDayOfMonth();
		const currentMonthTimes = self.times.filter(time => startOfMonth <= time.day);
		const selfSeconds: number = currentMonthTimes.reduce(
			(total, time) => total + time.d, 0);
		const subSeconds: number = self.subProjects.reduce(
			(total: any, proj: any) => total + proj.totalSecondsInCurrentMonth, 0);
		return selfSeconds + subSeconds;
	},

	get totalSecondsInLastThreeMonths(): number {
		const startOfMonth = getFirstDayXMonthsAgo(3);
		const currentMonthTimes = self.times.filter(time => startOfMonth <= time.day);
		const selfSeconds: number = currentMonthTimes.reduce(
			(total, time) => total + time.d, 0);
		const subSeconds: number = self.subProjects.reduce(
			(total: any, proj: any) => total + proj.totalSecondsInCurrentMonth, 0);
		return selfSeconds + subSeconds;
	},
}))
.views(self => ({
	get subProjectsByAlpha() {
		return self.subProjects.sort(byAlpha);
	},
}))
.views(self => ({
	timesByFilter(filter: IFilter) {
		return filter.filterTimes(self.times);
	},

	totalTimesByFilter(filter: IFilter) {
		return filter.filterTimes(self.totalTimes);
	},
}))
.views(self => ({
	subProjectsByFilter(filter: IFilter): IProject[] {
		return Array.from(
			self.totalTimesByFilter(filter)
			.reduce((acc, time) => {
				logger.log("[Project] reducing times of project '%s' to get subprojects. found: '%s'", self.name, time.pid.name);
				if (self.name !== time.pid.name) {
					acc.add(time.pid);
				}
				return acc;
			}, new Set<IProject>())) as IProject[];
	},

	usersByFilter(filter: IFilter): IUser[] {
		return Array.from(
			self.totalTimesByFilter(filter)
			.reduce((acc, time) => {
				acc.add(time.uid);
				return acc;
			}, new Set<IUser>())) as IUser[];
	},

	rolesByFilter(filter: IFilter): IRole[] {
		return Array.from(
			self.totalTimesByFilter(filter)
			.reduce((acc, time) => {
				acc.add(time.rid);
				return acc;
			}, new Set<IRole>())) as IRole[];
	},

	secondsByFilter(filter: IFilter): number {
		return self
			.timesByFilter(filter)
			.reduce((acc, time) => acc + time.d, 0);
	},

	totalSecondsByFilter(filter: IFilter): number {
		return self
			.totalTimesByFilter(filter)
			.reduce((acc, time) => acc + time.d, 0);
	},
}))
.views(self => ({
	subProjectsByFilterAndAlpha(filter: IFilter): IProject[] {
		return self.subProjectsByFilter(filter).sort(byAlpha);
	},

	hasTime(time: ITimeEntry): boolean {
		return self.times.indexOf(time) > -1
			|| self.subProjects.some((subProject: any) => subProject.hasTime(time));
	},
}));

export type IProjectModel = typeof Project.Type;
