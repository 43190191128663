import { types } from "mobx-state-tree";

import { User, IUser } from "./User";
import { Client, IClient } from "./Client";
import { Project, IProject } from "./Project";
import { Role, IRole } from "./Role";
import { OptionalNumber } from "./helpers";

export interface ITimeEntry {
	id: number;
	uid: IUser;
	cid: IClient;
	pid: IProject;
	rid: IRole;
	day: number;
	d: number;
	desc: string;
	start?: number;
	end?: number;
	billed: boolean;
	_r: string;
	rootProject: IProject;
}

export const TimeEntry = types.model("TimeEntry", {
	id: types.identifierNumber,
	uid: types.late(() => types.reference(User)),
	cid: types.late(() => types.reference(Client)),
	pid: types.late(() => types.reference(Project)),
	rid: types.late(() => types.reference(Role)),
	day: types.number,
	d: types.number,
	desc: types.string,
	start: OptionalNumber,
	end: OptionalNumber,
	billed: types.boolean,
	_r: types.string,
})
.views(self => ({
	get rootProject() {
		const rootProject = self.cid.rootProjects
			.find((project: IProject) => project.hasTime(self as any));

		if (rootProject == null) {
			throw new Error("ENOROOTPROJECTFOUND");
		}

		return rootProject;
	},
}));

export type ITimeEntryModel = typeof TimeEntry.Type;
