export function log(message?: any, ...optionalParams: any[]) {
	if (process.env.NODE_ENV !== "production") {
		// tslint:disable-next-line:no-console
		console.log(message, ...optionalParams);
	}
}

export function warn(message?: any, ...optionalParams: any[]) {
	if (process.env.NODE_ENV !== "production") {
		// tslint:disable-next-line:no-console
		console.warn(message, ...optionalParams);
	}
}

export function error(message?: any, ...optionalParams: any[]) {
	if (process.env.NODE_ENV !== "production") {
		// tslint:disable-next-line:no-console
		console.error(message, ...optionalParams);
	}
}
