import * as React from "react";
import { observer } from "mobx-react";
// import { text } from "../../../lib/i18n";
import Main from "./main/index";
import { RouteComponentProps } from "react-router";

interface IHomeScreenProps extends RouteComponentProps<any>, React.Props<any> {

}

@observer
export default class HomeScreen extends React.Component<IHomeScreenProps, {}> {
	render() {
		const { path } = this.props.match;

		if (path === "/") {
			return <Main />;
		}

		return <div>404 in homescreen</div>;
	}
}
