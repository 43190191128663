import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
// import registerServiceWorker from "./registerServiceWorker";
import { bootstrapApp } from "./lib/actions/lifecycle";
import "./styles/index.css";
import * as Api from "./lib/api";

ReactDOM.render(
	<App />,
	document.getElementById("root") as HTMLElement
);

// leave out the service worker for now
// registerServiceWorker();
bootstrapApp();

// attach api to global window object
// in development and testing
if (process.env.NODE_ENV !== "production") {
	(window as any).api = Api;
}
