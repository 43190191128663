import * as React from "react";
import { observer } from "mobx-react";
import Popover from "./Popover";
import "react-select/dist/react-select.css";
import defaultStore from "../../stores/index";
import { text } from "../../lib/i18n";

import { Aux } from "./Aux";

const icon = "fa fa-globe";
@observer
export default class PopoverLanguage extends React.Component {
	public render() {
		return (
			<Popover
				icon={icon}
				renderHeader={() => <div />}
				render={(close) => (
					<Aux>
						<div
							className="popover-select list-group"
						>
							<div onClick={() => { defaultStore.i18n.loadLang("de_DE"); close(); }}>{text("languages.german")}</div>
							<div onClick={() => { defaultStore.i18n.loadLang("en_US"); close(); }}>{text("languages.english")}</div>
						</div>
					</Aux>
				)}
				renderFooter={() => <div />}
			/>
		);
	}
}
