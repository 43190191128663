import * as React from "react";

import Navbar from "./Navbar";
import Breadcrumbs from "./Breadcrumbs";
import ErrorModal from "./ErrorModal";

interface IFrameProps {
	children: JSX.Element;
}

const Frame = (props: IFrameProps) => {
	const {
		children,
	} = props;

	return (
		<div className="home-screen container">
			<ErrorModal />
			<div className="row">
				<div className="col-md-12 content">
					<Navbar/>

					<div className="row breadcrumbs">
						<Breadcrumbs />
					</div>

					<div className="row main">
						<div className="col-sm-12 col">
							{children}
						</div>
					</div>

					<footer
						className="text-muted bg-light text-center py-3 d-flex align-items-end justify-content-center"
						style={{borderRadius: "0 0 4px 4px"}}
					>
						Made with
						<i className="px-1 pb-1 fa fa-heart" />
						by
						<a className="px-1" target="_blank" href="https://www.webmart.de/">WebMart</a>
						<span className="pl-2">
							<img src="https://d3anbvs0xpros8.cloudfront.net/assets/img/robo_medium.png" style={{width: "40px"}} />
						</span>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default Frame;
