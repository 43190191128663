import * as React from "react";
import { observer } from "mobx-react";

// import * as logger from "../../../../lib/logger";

import { IClient } from "../../../../stores/models/Client";
import PieChart from "../../../common/charts/PieChart";
import BarChart from "../../../common/charts/BarChart";
import PopoverSelect from "../../../common/PopoverSelect";
import PopoverMonthPicker from "../../../common/PopoverMonthPicker";
import { getMonthDayCount } from "../../../../lib/utils";
import ProjectTable from "./ProjectTable";
import RoleTable from "./RoleTable";
import ErrorBoundary from "../../../common/ErrorBoundary";
import { IFilter } from "../../../../stores/models/Filter";
import { text } from "../../../../lib/i18n";

interface IClientDetailsProps {
	client: IClient;
	clearSelectedClient: () => void;
}

@observer
export default class ClientDetails extends React.Component<IClientDetailsProps, {}> {
	public render() {
		const {
			handleMonthChange,
			handleProjectFilterChange,
			handleUserFilterChange,
			handleRoleFilterChange,
			props: {
				client,
			},
		} = this;

		const {
			isLoadingMonthsLastYear,
			monthHoursLastYear,
			isLoadingReport,
			filteredRootProjectsByAlpha,
		} = client;

		const topUserData = {
			labels: client.topFilteredUsers.map(el => el.meta),
			series: client.topFilteredUsers.map(el => el.value),
		};

		const topProjectData = {
			labels: client.topFilteredRootProjects.map(el => el.meta),
			series: client.topFilteredRootProjects.map(el => el.value),
		};

		return (
			<div className="view client-details">
				<h2 className="py-1 bg-light mx-n4 px-4 border-top">{text("clientDetails.header", client.name)}</h2>
				<div className="row">
					<div className="col-lg-7 col-md-12 mb-2">
						<div className="row">
							<div className="col-md-6 col-sm-12 mb-2">
								<h3>
									{text("clientDetails.topUsers")}
								</h3>
								{topUserData.series.length > 1
									? <PieChart data={topUserData} />
									: <>{text("clientDetails.table.emptyChart")}</>}
							</div>
							<div className="col-md-6 col-sm-12 mb-2">
								<h3>
									{text("clientDetails.topProjects")}
								</h3>
								{filteredRootProjectsByAlpha.length > 1
									? (<PieChart data={topProjectData} />)
									: <>{text("clientDetails.table.emptyChart")}</>
								}
							</div>
						</div>
					</div>
					<div className="col-lg-5 col-md-12">
						<h3>
							{text("clientDetails.lastTwelveMonths")}
						</h3>
						{ isLoadingMonthsLastYear ? (
							<span>{text("loading")}</span>
						) : (
							<BarChart
								data={monthHoursLastYear}
								settings={{
									height: 200
								}}
							/>
						) }
					</div>
				</div>
				<div className="row">
					<div className="col-lg-7 col-md-12 mb-2">
						<ErrorBoundary>
							<ProjectTable client={client}/>
						</ErrorBoundary>

						<ErrorBoundary>
							{(client.selectedRoleOpts.length === 0 || client.selectedRoleOpts.length > 1) ? (
								<RoleTable client={client}/>
							) : null}
						</ErrorBoundary>
					</div>
					<div className="col-lg-5 col-md-12 mb-2 filters">
						<div className="row">
							<div className="col-12">
								<PopoverMonthPicker
									icon="fa fa-cog"
									label={text("clientDetails.filters.selectMonth")}
									onChange={handleMonthChange}
									activeMonthAndYear={client.filter.activeMonthAndYear}
									client={client}
								/>
								<div className="mb-2">
									<strong>{text("clientDetails.filters.month")}</strong>
								</div>
								<span>
									{client.filter.monthYear}
								</span>
								<hr />
							</div>
							<div className="col-12">
								<PopoverSelect
									icon="fa fa-cog"
									label={text("clientDetails.filters.selectProjects")}
									className="dropdown-select"
									filter={client.filter}
									items={client.rootProjectsByAlpha}
									getSelected={(filter: IFilter) => filter.pids as any}
									setSelected={handleProjectFilterChange}
								/>
								<div className="mb-2">
									<strong>{text("clientDetails.filters.projects")}</strong>
								</div>
								<ul>
									{client.selectedProjectOpts.length
										? client.selectedProjectOpts.map(project => <li key={project.id}>{project.name}</li>)
										: <li>{text("clientDetails.filters.all")}</li>}
								</ul>
								<hr />
							</div>
							<div className="col-12">
								<PopoverSelect
									icon="fa fa-cog"
									label={text("clientDetails.filters.selectUsers")}
									className="dropdown-select"
									filter={client.filter}
									items={client.filteredByTimeRangeAndProjectsUsersByAlpha}
									getSelected={(filter: IFilter) => filter.uids as any}
									setSelected={handleUserFilterChange}
								/>
								<div className="mb-2">
									<strong>{text("clientDetails.filters.users")}</strong>
								</div>
								<ul>
									{client.selectedUserOpts.length
										? client.selectedUserOpts.map(user => <li key={user.id}>{user.name}</li>)
										: <li>{text("clientDetails.filters.all")}</li>}
								</ul>
								<hr />
							</div>
							<div className="col-12">
								<PopoverSelect
									icon="fa fa-cog"
									label={text("clientDetails.filters.selectRoles")}
									className="dropdown-select"
									filter={client.filter}
									items={client.filteredRolesByAlpha}
									getSelected={(filter: IFilter) => filter.rids as any}
									setSelected={handleRoleFilterChange}
								/>
								<div className="mb-2">
									<strong>{text("clientDetails.filters.roles")}</strong>
								</div>
								<ul>
									{client.selectedRoleOpts.length
										? client.selectedRoleOpts.map(role => <li key={role.id}>{role.name}</li>)
										: <li>{text("clientDetails.filters.all")}</li>}
								</ul>
							</div>
						</div>
						<div className="row">
							<div className="col my-3 position-relative d-flex justify-content-center align-items-center ">
								<button
									className="btn btn-primary btn-lg btn-block"
									onClick={this.handleReportButton}
									disabled={
										isLoadingReport || (!client.filteredRolesByAlpha.length && !client.filteredRootProjectsByAlpha.length)
									}
									title={text("clientDetails.reportBtn")}
								>
									{/* {text("clientDetails.reportBtn")} */}PDF
									<i className="px-2 fa fa-download" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private handleMonthChange = (shortYear: number, month: number) => {
		const dayStart = (shortYear * 10000) + (month * 100) + 1;
		const dayEnd = (shortYear * 10000) + (month * 100) + getMonthDayCount(shortYear, month);
		// update the filter
		this.props.client.filter.setDayRange(dayStart, dayEnd);
		this.props.client.fetchTimes();
	}

	private handleProjectFilterChange = (selectedIds: number[]) => {
		this.props.client.filter.setPids(selectedIds);
		this.props.client.fetchAvailableMonths();
	}

	private handleUserFilterChange = (selectedIds: number[]) => {
		this.props.client.filter.setUids(selectedIds);
		this.props.client.fetchAvailableMonths();
	}

	private handleRoleFilterChange = (selectedIds: number[]) => {
		this.props.client.filter.setRids(selectedIds);
		this.props.client.fetchAvailableMonths();
	}

	private handleReportButton = () => {
		this.props.client.fetchReportUrl();
	}
}
