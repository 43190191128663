import { types } from "mobx-state-tree";
import { Session } from "./Session";
import { ExUser } from "./ExUser";
import { Org, IOrgModel } from "./Org";

import * as logger from "../../lib/logger";

import stores from "..";
import { optionalType } from "./helpers";
import { Role } from "./Role";
import { Client, IClientModel } from "./Client";
import { Project } from "./Project";
import { User } from "./User";

export const DataModel = types.model("DataModel", {
	session: Session,
	exuser: ExUser,
	orgs: types.late(() => types.array(Org)),
	roles: types.late(() => types.array(Role)),
	clients: types.late(() => types.array(Client)),
	projects: types.late(() => types.array(Project)),
	users: types.late(() => types.array(User)),
	selectedOrg: types.late(() => optionalType(types.reference(Org))),
	selectedClient: types.late(() => optionalType(types.reference(Client))),
})
.views(self => ({
	getOrgById(id: number) {
		return self.orgs.find(org => org.id === id);
	},
}))
.actions(self => ({
	setSelectedOrg(org: IOrgModel) {
		self.selectedOrg = org;
		stores.userInterface.setLoggedIn();
		logger.log("[data] selected org", self.selectedOrg.name);
	},

	setSelectedClient(client: IClientModel) {
		self.selectedClient = client;
		logger.log("[data] selected client", self.selectedClient.name);
	},

	clearSelectedClient() {
		self.selectedClient = null;
		logger.log("[data] cleared selected client");
	},
}));

export type IDataModel = typeof DataModel.Type;
