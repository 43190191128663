import * as React from "react";
import { observer } from "mobx-react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";

import stores from "../../stores";
import { text } from "../../lib/i18n";

interface IErrorModalProps {
	// className: string;
	// isRetryAble: boolean;
	// isCritical: boolean;
	// retry?: () => void;
	// close?: () => void;
	// error: any;
	// clearError?: () => void;
}

@observer
export default class ErrorModal extends React.Component<IErrorModalProps, {}> {
	public render() {
		const {
			userInterface: {
				hasError,
				errorData: {
					err,
				},
			}
		} = stores;

		let title = text("errors.title");
		let message = text("errors.message");

		if (err && typeof err === "object") {
			if (err.i18nKey && typeof err.i18nKey === "string") {
				title = text(`errors.${err.i18nKey}.title`);
				message = text(`errors.${err.i18nKey}.message`);
			}
		}

		return (
			<Modal
				isOpen={hasError}
				className={"error-modal"}
				backdrop={"static"}
			>
				<ModalHeader>
					{title}
				</ModalHeader>
				<ModalBody>
					{message}
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={this.handleClick}
						color="danger"
					>
						{text("errors.reloadBtn")}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	private handleClick() {
		window.location.reload(true);
	}
}
