import { types, getParent, getSnapshot, IModelType, flow } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import * as Api from "../../lib/api";

// import stores from "../../stores";
import * as logger from "../../lib/logger";
import {
	getFirstDayXMonthsAgo,
	getLastDayXMonthsAgo,
	convertApiDateToTs,
	getXthPreviousMonthYear,
	getLast3MonthNames
} from "../../lib/utils";

import { Org, IOrg } from "./Org";
import { IDataModel } from "./Data";
import { IProject, IProjectModel } from "./Project";
import { ITimeEntry } from "./TimeEntry";
import { IFilter, Filter } from "./Filter";
import { IUser } from "./User";
import { IRole } from "./Role";
import { byAlpha, byYearDesc } from "../../lib/sorting";
import { IPieChartDataPoint } from "../../components/common/charts/PieChart";
import { getTopItems, getItemsFromTimes } from "./helpers";
import { AvailableMonth, IAvailableMonth } from "./AvailableMonth";
import stores from "..";

export interface IClient {
	id: number;
	oid: IOrg;
	name: string;

	projects: IObservableArray<IProject>;
	rootProjects: IObservableArray<IProject>;
	times: IObservableArray<ITimeEntry>;
	roles: IRole[];
	timeCount: number;
	hoursCurrentMonth: number;
	hoursPreviousMonth: number;
	hours2ndPreviousMonth: number;
	filteredHoursXthPreviousMonth: (xth: number) => number;

	heatmapData: {
		[date: string]: number;
	};
	hasHeatmapData: boolean;
	barChartData: {
		labels: [string, string, string];
		series: [[number, number, number]];
	};

	topRootProjects: IObservableArray<IProject>;
	rootProjectsByAlpha: any|IObservableArray<IProject>;
	totalSeconds: number;
	filter: IFilter;
	filteredTotalSeconds: number;
	filteredRootProjectsByAlpha: IProject[];
	filteredRolesByAlpha: IRole[];

	filteredByTimeRangeRootProjectsByAlpha: IProject[];
	filteredByTimeRangeAndProjectsUsersByAlpha: IUser[];

	rolesByAlpha: IRole[];
	usersByAlpha: IUser[];

	selectedUserOpts: IUser[];
	selectedRoleOpts: IRole[];
	selectedProjectOpts: IProject[];

	topFilteredUsers: IPieChartDataPoint[];
	topFilteredRootProjects: IPieChartDataPoint[];

	monthHoursLastYear: {
		labels: string[];
		series: number[];
	};

	availableMonths: IObservableArray<IAvailableMonth>;
	availableMonthsByYear: {year: number, months: number[]}[];
	isLoadingAvailableMonths: boolean;
	isLoadingMonthsLastYear: boolean;
	isLoadingTimes: boolean;
	isLoadingReport: boolean;

	fetchAvailableMonths: () => Promise<any>;
	fetchTimes: () => Promise<any>;
	fetchReportUrl: () => Promise<any>;
}

export const Client: IModelType<any, any> = types.model("Client", {
	id: types.identifierNumber,
	oid: types.late(() => types.reference(Org)),
	name: types.string,
	filter: Filter,
	availableMonths: types.array(AvailableMonth),
	isLoadingAvailableMonths: types.optional(types.boolean, false),
	isLoadingMonthsLastYear: types.optional(types.boolean, false),
	isLoadingTimes: types.optional(types.boolean, false),
	isLoadingReport: types.optional(types.boolean, false),
})
.preProcessSnapshot(snapshot => {
	const newSnapshot = {
		...snapshot,
		filter: {
			oid: snapshot.oid,
			cids: [snapshot.id],
		},
		availableMonths: [],
	};
	return newSnapshot;
})
.views(self => ({
	get projects() {
		const data: IDataModel = getParent(self, 2);
		return data.projects.filter((project: any) => project.cid === self) as any;
	},

	get times(): IObservableArray<ITimeEntry> {
		return self.oid.times.filter((time: ITimeEntry) => time.cid === self);
	},
}))
.views(self => ({
	get rootProjects() {
		const { projects } = self;
		const projectIds = projects.map((project: IProjectModel) => project.id);
		return (
			projects
				.filter(
					(project: IProjectModel) => project.pid === 0 || projectIds.indexOf(project.pid) === -1
				) as IObservableArray<any>
		);
	},

	get timeCount() {
		return self.times.length;
	},

	get totalSeconds() {
		return self.times.reduce((acc, current) => acc + current.d, 0);
	},

	get hoursCurrentMonth() {
		const currMonthStart = getFirstDayXMonthsAgo(0);
		const currentMonthTimes = self.times.filter(time => currMonthStart <= time.day);
		const seconds = currentMonthTimes.reduce((total, current) => total + current.d, 0);
		return Math.floor(seconds / 3600);
	},

	get hoursPreviousMonth() {
		const monthStart = getFirstDayXMonthsAgo(1);
		const monthEnd = getLastDayXMonthsAgo(1);
		const currentMonthTimes = self.times.filter(time =>
			monthStart <= time.day && time.day <= monthEnd
		);
		const seconds = currentMonthTimes.reduce((total, current) => total + current.d, 0);
		return Math.floor(seconds / 3600);
	},

	get hours2ndPreviousMonth() {
		const monthStart = getFirstDayXMonthsAgo(2);
		const monthEnd = getLastDayXMonthsAgo(2);
		const currentMonthTimes = self.times.filter(time =>
			monthStart <= time.day && time.day <= monthEnd
		);
		const seconds = currentMonthTimes.reduce((total, current) => total + current.d, 0);
		return Math.floor(seconds / 3600);
	},

	filteredHoursXthPreviousMonth(xth: number) {
		const firstDayXMonthsAgo = getFirstDayXMonthsAgo(xth);

		logger.log("firstDayXMonthsAgo", firstDayXMonthsAgo);

		const yymm = Math.floor(firstDayXMonthsAgo / 100);

		logger.log("yymm: %d", yymm);

		const monthData = self.availableMonths.find(month => month.yymm === yymm);

		logger.log("monthData:", monthData);

		if (monthData == null) {
			return 0;
		} else {
			return Math.floor(monthData.d / 3600);
		}
	},

	get heatmapData() {
		const buckets = {} as { [ts: number]: number };

		for (let i = 0; i < self.times.length; i++) {
			const date = convertApiDateToTs(self.times[i].day);
			let counter = buckets[date] || 0;
			buckets[date] = counter + self.times[i].d / 3600;
		}

		return buckets;
	},
}))
.views(self => ({
	get hasHeatmapData() {
		return Object.keys(self.heatmapData).length > 0;
	},

	get barChartData() {
		return {
			labels: getLast3MonthNames(),
			series: [
				[self.hours2ndPreviousMonth, self.hoursPreviousMonth, self.hoursCurrentMonth],
			] as [[number, number, number]],
		};
	},

	get topRootProjects() {
		return self.rootProjects.slice()
			.filter(project => project.totalSecondsInLastThreeMonths > 0)
			.sort((a, b) => {
				if (a.totalSecondsInLastThreeMonths > b.totalSecondsInLastThreeMonths) {
					return -1;
				}
				if (a.totalSecondsInLastThreeMonths < b.totalSecondsInLastThreeMonths) {
					return +1;
				}
				return 0;
			})
			.slice(0, 5) as IObservableArray<IProject>;
	},

	get rootProjectsByAlpha() {
		return self.rootProjects.sort(byAlpha).filter(project => project.totalSecondsInCurrentMonth > 0);
	},

	get totalSeconds() {
		return self.rootProjects.reduce((acc: number, project: IProject) => {
			return acc + project.totalSeconds;
		}, 0);
	},
}))
.views(self => ({
	get users() {
		return Array.from(self.times.reduce((acc, time) => {
			acc.add(time.uid);
			return acc;
		}, new Set<IUser>()));
	},

	get roles() {
		return Array.from(self.times.reduce((acc, time) => {
			acc.add(time.rid);
			return acc;
		}, new Set<IRole>()));
	},

	get filteredTimes() {
		return self.filter.filterTimes(self.times);
	},
}))
.views(self => ({
	get filteredProjects() {
		return Array.from(self.filteredTimes
			.reduce((acc, time) => {
				acc.add(time.pid);
				return acc;
			}, new Set<IProject>()));
	},

	get filteredUsers() {
		return Array.from(self.filteredTimes
			.reduce((acc, time) => {
				acc.add(time.uid);
				return acc;
			}, new Set<IUser>()));
	},

	get filteredRoles() {
		return Array.from(self.filteredTimes
			.reduce((acc, time) => {
				acc.add(time.rid);
				return acc;
			}, new Set<IRole>()));
	},

	get filteredTotalSeconds() {
		return self.filteredTimes.reduce((acc, time) => {
			return acc + time.d;
		}, 0);
	}
}))
.views(self => ({
	get filteredRoles() {
		return getItemsFromTimes(
			self.filteredTimes,
			time => time.rid,
		);
	},

	get filteredRootProjects() {
		return getItemsFromTimes(
			self.filteredTimes,
			time => time.rootProject,
		);
	},

	get filteredByTimeRangeRootProjectsByAlpha() {
		return getItemsFromTimes(
			self.filter.byRange(self.times),
			time => time.rootProject
		).sort(byAlpha);
	},

	get filteredByTimeRangeAndProjectsUsersByAlpha() {
		return getItemsFromTimes(
			self.filter.byRootProject(
				self.filter.byRange(self.times)
			),
			time => time.uid
		).sort(byAlpha);
	}
}))
.views(self => ({
	get filteredRolesByAlpha() {
		return self.filteredRoles.sort(byAlpha);
	},

	get filteredRootProjectsByAlpha() {
		return self.filteredRootProjects.sort(byAlpha);
	},

	get selectedProjectOpts() {
		return self.filter.pids.map(pid => {
			return self.rootProjects.find(project => project.id === pid) as IProject;
		});
	},

	get usersByAlpha() {
		return self.users.sort(byAlpha);
	},

	get selectedUserOpts() {
		return self.filter.uids.map(uid => {
			return self.users.find(user => user.id === uid) as IUser;
		});
	},

	get rolesByAlpha() {
		return self.roles.sort(byAlpha);
	},

	get selectedRoleOpts() {
		return self.filter.rids.map(rid => {
			return self.roles.find(role => role.id === rid) as IRole;
		});
	},

	get topFilteredUsers() {
		return getTopItems(
			self.filteredTimes,
			(time) => time.uid,
		);
	},

	get topFilteredRootProjects() {
		return getTopItems(
			self.filteredTimes,
			time => time.rootProject,
		);
	},

	get availableMonthsByYear() {
		return Array.from(
			self.availableMonths
				.reduce((acc, month) => {
					const year = 2000 + Math.floor(month.yymm / 100);
					const months = acc.get(year) || [];
					months.push(month.yymm % 100);
					acc.set(year, months);
					return acc;
				}, new Map<number, number[]>())
				.entries()
		).map(([year, months]) => {
			return {
				year,
				months,
			};
		}).sort(byYearDesc);
	},

	get monthHoursLastYear() {
		const labels: string[] = [];
		const dataPoints: number[] = [];

		for (let i = 11; i >= 0; i--) {
			labels.push(getXthPreviousMonthYear(i));

			dataPoints.push(self.filteredHoursXthPreviousMonth(i));
		}

		return {
			labels,
			series: dataPoints,
		};
	}
}))
.actions(self => ({
	fetchAvailableMonths: flow(function *() {
		// TODO: cache response and use filter settings as key
		try {
			self.isLoadingAvailableMonths = true;
			const months = yield Api.getAvailableMonths(self.filter.groupedFilter);
			self.availableMonths.replace(months as any);
		} catch (err) {
			logger.error("could not load available months", err);
			stores.userInterface.setError(err, {
				action: "models.client.fetchAvailableMonths",
				ts: Date.now(),
				oid: self.oid.id,
				user: (getParent(self, 2) as IDataModel).session.id,
			});
		} finally {
			self.isLoadingAvailableMonths = false;
		}
	}),

	fetchTimes: flow(function *() {
		try {
			logger.log(`[CLIENT ${self.id}] fetching times`);
			self.isLoadingTimes = true;
			const filterSnapshot = getSnapshot(self.filter) as any;
			const times = yield Api.getTimes(filterSnapshot);
			logger.log(`[CLIENT ${self.id}] fetched times, merging`);
			(self.oid.mergeTimes as any)(times);
			logger.log(`[CLIENT ${self.id}] finished`);
		} catch (err) {
			logger.error("An unhandled error occured while fetching client times", err);
			stores.userInterface.setError(err, {
				action: "models.client.fetchTimes",
				ts: Date.now(),
				oid: self.oid.id,
				user: (getParent(self, 2) as IDataModel).session.id,
			});
		} finally {
			self.isLoadingTimes = false;
		}
	}),

	fetchReportUrl: flow(function *() {
		try {
			logger.log(`[CLIENT ${self.id}] fetching report url`);
			self.isLoadingReport = true;
			const filterSnapshot = getSnapshot(self.filter) as any;
			const answer: any = yield Api.getReportUrl(filterSnapshot);
			if (answer.url) {
				logger.log(`[CLIENT ${self.id}] report url: ${answer.url}`);
				window.open(answer.url, "_blank");
			} else {
				logger.log(`[CLIENT ${self.id}] api did not respond with report url`);
				stores.userInterface.setError("api-no-report-url", {
					action: "models.client.fetchReportUrl",
					ts: Date.now(),
					oid: self.oid.id,
					user: (getParent(self, 2) as IDataModel).session.id,
				});
			}
		} catch (err) {
			logger.error("An unhandled error occured while fetching client times", err);
			stores.userInterface.setError(err, {
				action: "models.client.fetchReportUrl",
				ts: Date.now(),
				oid: self.oid.id,
				user: (getParent(self, 2) as IDataModel).session.id,
			});
		} finally {
			self.isLoadingReport = false;
		}
	}),
}));

export type IClientModel = typeof Client.Type;
