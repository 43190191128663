import * as React from "react";
import { observer } from "mobx-react";

import { IClient } from "../../../../stores/models/Client";
import { secs2HoursFixed } from "../../../../lib/utils";
import { text } from "../../../../lib/i18n";

interface IRoleTableProps {
	client: IClient;
}

@observer
export default class RoleTable extends React.Component<IRoleTableProps, {}> {
	public render() {
		const {
			client: {
				filter,
				filteredRolesByAlpha,
				filteredTotalSeconds,
				isLoadingTimes,
			},
		} = this.props;

		return (
			<table className={`table table-sm table-hover ${isLoadingTimes ? "loading" : ""}`}>
				<thead>
					<tr className="text-uppercase">
						<th scope="col" >{text("clientDetails.table.headings.role")}</th>
						<th scope="col" className="text-right">{text("clientDetails.table.headings.hours")}</th>
					</tr>
				</thead>
				<tbody>
					{filteredRolesByAlpha.length
					? (
						[
							filteredRolesByAlpha.map(role => (
								<tr key={role.id}>
									<td className="text-truncate name">
										<span className="arrow-wrp" />
										<span className="content">{role.name}</span>
									</td>
									<td className="text-right hours">
										{secs2HoursFixed(role.secondsByFilter(filter))}h
									</td>
								</tr>
							))
						,
							<tr
								key={-1}
							>
								<td className="text-truncate">
									<span className="arrow-wrp" />
									{text("clientDetails.table.headings.sum")}
								</td>
								<td className="text-right">
									<b>{secs2HoursFixed(filteredTotalSeconds)}h</b>
								</td>
							</tr>
						]
					) : (
						<tr className="empty">
							<td colSpan={2}>{text("clientDetails.table.empty")}</td>
						</tr>
					)}
				</tbody>
			</table>
		);
	}
}
