import { types } from "mobx-state-tree";
import { Org, IOrg } from "./Org";

export interface IUser {
	id: number;
	oid: IOrg;
	name: string;
	hidden: boolean;
}

export const User = types.model("User", {
	id: types.identifierNumber,
	oid: types.late(() => types.reference(Org)),
	name: types.string,
	hidden: types.boolean,
});

export type IUserModel = typeof User.Type;
