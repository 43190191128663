import * as React from "react";

interface IAuxProps {
	children?: React.ReactNode;
}
export class Aux extends React.Component<IAuxProps, any> {
	public render() {
		return this.props.children;
	}
}

export default Aux;
