// import { action, observable } from "mobx";
import { BaseState } from "./_BaseState";

export class Config extends BaseState {
	// application version
	public readonly version: string = process.env.REACT_APP_VERSION || "";

	// local storage prefix
	public readonly localStoragePrefix = "EXTWMTT:";

	// http api configuration
	public readonly apiUrl: string = process.env.REACT_APP_API_URL || "";

	// /**
	//  * Number of months before the current month to fetch and display
	//  * eg in the heatmap
	//  *
	//  * @type {number}
	//  * @memberof Config
	//  */
	// public readonly prevMonths: number = 2;
}
