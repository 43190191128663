/**
 * DON'T CHANGE THIS FILE! IT IS AUTOGENERATED BY `npm run gen-lang-splitpoints`
 * IF YOU REALLY NEED TO CHANGE SOMETHING, CHANGE IT THERE!!!
 * YOU HAVE BEEN WARNED
 */

import { IBundle } from "./interfaces";

import de_DE_Bundle from "../i18n/de_DE";

export class Languages {
	public static async de_DE(): Promise<IBundle> {
		return de_DE_Bundle;
	}

	// how to use webpack's dynamic import system
	// https://webpack.js.org/api/module-methods/#import-

	public static async en_US(): Promise<IBundle> {
		return (await import(
			/* webpackChunkName: "lang.en_US" */
			"../i18n/en_US"
		)).bundle;
	}
}

export type TLanguage = keyof typeof Languages;
