import { types } from "mobx-state-tree";
import { observable } from "mobx";
import * as moment from "moment";

import { Org } from "./Org";
import { ITimeEntry } from "./TimeEntry";
import { IGroupedFilter } from "../../lib/interfaces";

// import * as logger from "../../lib/logger";

const DEFAULT_DAYSTART = () => parseInt(moment().startOf("month").format("YYMMDD"), 10);
const DEFAULT_DAYEND = () => parseInt(moment().endOf("month").format("YYMMDD"), 10);
export const DEFAULT_LIMIT = 20000;

const ExclusiveStartKey = types.model("ExclusiveStartKey", {
	day: types.number,
	id: types.number,
});

export const Filter = types.model({
	oid: types.late(() => types.reference(Org)),
	cids: types.array(types.number),
	uids: types.optional(
		types.array(types.number),
		[],
	),
	rids: types.optional(
		types.array(types.number),
		[],
	),
	pids: types.optional(
		types.array(types.number),
		[],
	),
	isbilled: types.optional(
		types.union(types.boolean, types.null),
		null
	),
	daystart: types.optional(types.number, DEFAULT_DAYSTART()),
	dayend: types.optional(types.number, DEFAULT_DAYEND()),
	limit: types.optional(types.number, DEFAULT_LIMIT),
	esk: types.optional(
		types.union(ExclusiveStartKey, types.undefined),
		undefined,
	),
})
.views(self => ({
	get groupedFilter(): IGroupedFilter {
		const { oid, cids, pids, rids, uids, isbilled } = self;
		return {oid: oid.id , cids, pids, rids, uids, isbilled};
	},

	get monthYear() {
		return moment(self.daystart, "YYMMDD").format("MMM YYYY");
	},

	get activeMonthAndYear() {
		const dayStart = moment(self.daystart, "YYMMDD");

		return {
			month: parseInt(dayStart.format("MM"), 10),
			year: parseInt(dayStart.format("YY"), 10),
		};
	},

	// filter by time range
	byRange(times: ITimeEntry[]): ITimeEntry[] {
		const { daystart, dayend } = self;
		return times
			.slice()
			.filter(time => daystart <= time.day && time.day <= dayend);
	},

	// filter times by root projects
	byRootProject(times: ITimeEntry[]): ITimeEntry[] {
		if (self.pids.length === 0) {
			return times.slice();
		} else {
			return times
				.slice()
				.filter(time => self.pids.indexOf(time.rootProject.id) > -1);
		}
	},

	// filter times by users
	byUser(times: ITimeEntry[]): ITimeEntry[] {
		if (self.uids.length === 0) {
			return times.slice();
		} else {
			return times
				.slice()
				.filter(time => self.uids.indexOf(time.uid.id) > -1);
		}
	},

	// filter times by roles
	byRole(times: ITimeEntry[]): ITimeEntry[] {
		if (self.rids.length === 0) {
			return times.slice();
		} else {
			return times
				.slice()
				.filter(time => self.rids.indexOf(time.rid.id) > -1);
		}
	},

	// filter times by billing state
	byBilled(times: ITimeEntry[]): ITimeEntry[] {
		if (self.isbilled == null) {
			return times.slice();
		} else {
			return times
				.slice()
				.filter(time => time.billed === self.isbilled);
		}
	},

	// filter times by limit
	byLimit(times: ITimeEntry[]): ITimeEntry[] {
		if (self.limit <= 0) {
			return times.slice();
		} else {
			return times.slice(0, self.limit);
		}
	},

	// filter times by client
	byClient(times: ITimeEntry[]): ITimeEntry[] {
		return times
			.slice()
			.filter(time => self.cids.indexOf(time.cid.id) > -1);
	},
}))
.views(self => ({
	filterTimes(times: ITimeEntry[]): ITimeEntry[] {
		// times are already pre-filtered by oid and cid
		let result = times;

		result = self.byClient(result);
		result = self.byRange(result);
		result = self.byBilled(result);
		result = self.byRootProject(result);
		result = self.byUser(result);
		result = self.byRole(result);
		result = self.byLimit(result);
		return result;
	}
}))
.actions(self => ({
	setDayRange(
		daystart: number = DEFAULT_DAYSTART(),
		dayend: number = DEFAULT_DAYSTART()) {
		self.daystart = daystart;
		self.dayend = dayend;
	},

	setUids(uids: number[] = []) {
		self.uids = observable.array(uids);
	},

	setRids(rids: number[] = []) {
		self.rids = observable.array(rids);
	},

	setPids(pids: number[] = []) {
		self.pids = observable.array(pids);
	},

	setBilled(isbilled: boolean|null = null) {
		self.isbilled = isbilled;
	},

	setLimit(limit: number = DEFAULT_LIMIT) {
		self.limit = limit;
	},

	setEsk(esk: IExclusiveStartKey|undefined = undefined) {
		self.esk = esk;
	},
}))
.actions(self => ({
	reset() {
		self.setDayRange();
		self.setUids();
		self.setPids();
		self.setRids();
		self.setBilled();
		self.setLimit();
		self.setEsk();
	},
}));

export type IFilter = typeof Filter.Type;
export type IExclusiveStartKey = typeof ExclusiveStartKey.Type;
