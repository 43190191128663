import { action } from "mobx";
import * as Api from "../api";
import { sleep } from "../utils";
import stores from "../../stores";
import { LOGIN_STATE } from "../enums";
import * as logger from "../logger";
import * as Utils from "../utils";

export const showSessionError = action(function (meta?: any) {
	Utils.nukeCookie();

	stores.userInterface.setError({
		i18nKey: "session_invalid",
	}, {
		...meta,
		ts: Date.now(),
	});
});

const { userInterface } = stores;

/**
 * Login as external user
 *
 * @export
 * @param {string} email user email address
 * @param {string} text text entered by user
 * @param {string} token token provided by webmart
 */
export const login = action(async function(email: string, text: string, token: string) {
	try {
		// set loginState to MAIL_SENDING
		userInterface.loginState = LOGIN_STATE.MAIL_SENDING;

		// send login request
		const apiResp = await Api.login(email, text, token);

		if (process.env.NODE_ENV !== "production") {
			if (apiResp.emailtoken) {
				logger.warn(`Login Path: /api/orgs/login/${apiResp.emailtoken}`);
				const { protocol, host } = window.location;
				logger.warn(`Full: ${protocol}//${host}/api/orgs/login/${apiResp.emailtoken}`);
			}
		}

		// set loginState to MAIL_SENT
		userInterface.loginState = LOGIN_STATE.MAIL_SENT;

		// start polling the login status and
		// keep doing while we are not logged in
		let isPending = true;
		do {
			// sleep 3s
			await sleep(3000);
			// get login state
			const loginState = await Api.pollLoginStatus(apiResp.polltoken);
			// update loop condition
			isPending = loginState === "pending";
		} while (isPending);

		// set loginState to LINK_CLICKED
		userInterface.loginState = LOGIN_STATE.LINK_CLICKED;

		// wait 0.5s
		await sleep(500);

		// fetch homedata
		const homeData = await Api.getObsHomeData();
		stores.setData(homeData);

		if (stores.data.orgs.length === 0) {
			logger.error("external user has no org - showing error message");
			showSessionError();
			return;
		}

		// select the last selected (default: first) org and skip the login-screen
		const userId = stores.data.session.id;
		const selectedOrgId = Utils.load<number>(`SELECTED_ORG:${userId}`);
		const org = stores.data.orgs.find((orgItem: any) => orgItem.id === selectedOrgId)
			|| stores.data.orgs[0];

		(org as any).select();
		stores.userInterface.loginState = LOGIN_STATE.LOGGED_IN;
		await (org as any).fetchTimes();
		// we are done here
	} catch (err) {
		logger.error("[Session] An error occured", err);
		stores.userInterface.setError(err, {
			action: "session.login",
			ts: Date.now(),
		});
	}
});
