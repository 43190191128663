import * as React from "react";
import { observer } from "mobx-react";

import { IProject } from "../../../../stores/models/Project";
import { IFilter } from "../../../../stores/models/Filter";
import { secs2HoursFixed } from "../../../../lib/utils";

interface ISubProjectRowProps {
	project: IProject;
	filter: IFilter;
}

@observer
export default class SubProjectRow extends React.Component<ISubProjectRowProps, {}> {
	public render() {
		const {
			project: {
				id,
				name,
				totalSecondsByFilter,
			},
			filter,
		} = this.props;

		const totalFilteredSeconds = totalSecondsByFilter(filter);

		return (
			<tr
				className="sub-project"
				key={id}
			>
				<td className="text-truncate name">
					{name}
				</td>
				<td className="text-right hours">
					{secs2HoursFixed(totalFilteredSeconds)}h
				</td>
			</tr>
		);
	}
}
