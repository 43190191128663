import { HttpError } from "../errors";
import { action } from "mobx";
import * as Api from "../api";
import * as Utils from "../utils";
import stores from "../../stores";
import * as logger from "../logger";
import { LOGIN_STATE } from "../enums";
import { showSessionError } from "./session";

export const bootstrapApp = action(async function() {
	logger.log("[BOOTSTRAP] start");

	// don't bootstrap the app on a login-landing page
	if (stores.isOnLoginLandingPage) {
		logger.log("[BOOTSTRAP] on login page - skipping bootstrap procedure");
		return;
	}

	try {
		try {
			logger.log("[BOOTSTRAP] fetching home data");
			const homeData = await Api.getObsHomeData();

			// logger.log("[BOOTSTRAP] fetched homedata:", JSON.stringify(homeData));

			// inject data into the state-tree
			stores.setData(homeData);

			if (stores.data.orgs.length === 0) {
				logger.error("external user has no org - showing error message");
				showSessionError();
				return;
			}

			// select the last selected (default: first) org and skip the login-screen
			const userId = stores.data.session.id;
			const selectedOrgId = Utils.load<number>(`SELECTED_ORG:${userId}`);
			const org = stores.data.orgs.find((orgItem: any) => orgItem.id === selectedOrgId)
				|| stores.data.orgs[0];
			(org as any).select();
			stores.userInterface.loginState = LOGIN_STATE.LOGGED_IN;
			// fetch times for selected org
			await (org as any).fetchTimes();

		} catch (err) {
			logger.warn("[BOOTSTRAP] catched error");

			// oops!
			// check if we did not have a session
			if (err instanceof HttpError && err.response != null) {
				if (err.response.status === 403) {
					// we don't have a session
					// disable loading spinner and show login screen
					stores.userInterface.setIsBootstrapping(false);
					logger.log("[BOOTSTRAP] not logged in!");
				} else if (err.response.status === 404) {
					const json = await err.response.json();
					if (json && json.message && json.message === "external user not found") {
						logger.error("external user not found - showing error message");
						showSessionError(err);
						return;
					}
				} else {
					throw err;
				}
			} else {
				throw err;
			}
		}
	} catch (err) {
		if (process.env.NODE_ENV !== "production") {
			// tslint:disable-next-line:no-console
			console.error(err);
			throw err;
		}

		stores.userInterface.setError(err, {
			action: "lifecycle.bootstrapApp",
			ts: Date.now(),
		});
	}
});

export const logout = action(async function() {
	logger.log("[LOGOUT] start");

	logger.log("[LOGOUT] nuking token cookie (API)");
	await Api.logout();

	// nuke cookie at clientside - safety measure
	logger.log("[LOGOUT] nuking token cookie");
	Utils.nukeCookie();

	logger.log("[LOGOUT] reloading");
	window.location.reload(true);
});
