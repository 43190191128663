import { types, IType } from "mobx-state-tree";
import { IPieChartDataPoint } from "../../components/common/charts/PieChart";
import { ITimeEntry } from "./TimeEntry";
import { IHasName, entriesByDuration } from "../../lib/sorting";

export const OptionalNumber = types.union(
	types.number,
	types.null,
	types.undefined,
);

export const OptionalString = types.union(
	types.string,
	types.null,
	types.undefined,
);

export const OptionalBoolean = types.union(
	types.boolean,
	types.null,
	types.undefined,
);

export function optionalType(type: IType<any, any, any>) {
	return types.union(
		type,
		types.null,
		types.undefined,
	);
}

/**
 * get X top items of a list by a number score
 * the score is selected from an item by the given selector function
 *
 * @export
 * @template T
 * @param {ITimeEntry[]} times
 * @param {(time: ITimeEntry) => T} itemSelector
 * @param {number} [topCount=3]
 * @returns {IPieChartDataPoint[]}
 */
export function getTopItems<T extends IHasName>(
	times: ITimeEntry[],
	itemSelector: (time: ITimeEntry) => T,
	topCount: number = 3): IPieChartDataPoint[] {

	// create an array of [item, duration] - pairs (eg [userA, 100] or [roleB, 12])
	// filter out pairs with no duration
	// and sort them by duration DESC
	const itemsWithTimes = Array.from(times
		.reduce((acc, time) => {
			const item = itemSelector(time);
			const current = acc.get(item) || 0;
			acc.set(item, current + time.d);
			return acc;
		}, new Map<T, number>())
		.entries())
		.filter(entry => entry[1] > 0 && entry[0] != null) // filter empty entries
		.sort(entriesByDuration);

	const topX = itemsWithTimes.slice(0, topCount);

	const leftoverDuration = itemsWithTimes
		.slice(topCount)
		.reduce((acc, entry) => acc + entry[1], 0);

	// return a list with the `topCount` top performers
	let result = topX.map(entry => ({
		meta: entry[0].name,
		value: entry[1],
	}));

	// and the aggregated rest
	if (leftoverDuration > 0) {
		result = result.concat({
			meta: "Rest",
			value: leftoverDuration,
		});
	}

	// show an empty chart
	if (result.length === 0) {
		result = [{
			meta: "Empty",
			value: 100,
		}];
	}

	return result;
}

export function getItemsFromTimes<T>(times: ITimeEntry[], itemSelector: (time: ITimeEntry) => T): T[] {
	return Array.from(times.reduce((acc, time) => {
		acc.add(itemSelector(time));
		return acc;
	}, new Set<T>()));
}
