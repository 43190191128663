import { types, getParent, IModelType, flow, applySnapshot } from "mobx-state-tree";
import { IObservableArray } from "mobx";

import * as logger from "../../lib/logger";
import * as Api from "../../lib/api";
import * as Utils from "../../lib/utils";
// import { optionalType } from "./helpers";
import stores from "..";

import { IDataModel } from "./Data";
import { TimeEntry, ITimeEntry } from "./TimeEntry";
import { IClient } from "./Client";
import { IProject } from "./Project";
import { IRole } from "./Role";
import { IUser } from "./User";
import { getFirstDayXMonthsAgo } from "../../lib/utils";
import { DEFAULT_LIMIT } from "./Filter";

export interface IOrg {
	id: number;
	name: string;

	select?: () => void;

	clients?: IObservableArray<IClient>;
	projects?: IObservableArray<IProject>;
	rootProjects?: IObservableArray<IProject>;
	users?: IObservableArray<IUser>;
	roles?: IObservableArray<IRole>;
	times?: any|IObservableArray<ITimeEntry>;

	areTimesLoaded?: boolean;
	clientCount?: number;
	projectCount?: number;
	timeCount?: number;
	totalSeconds?: number;
	fetchTimes?: () => Promise<void>;
	mergeTimes?: (times: Array<any>) => void;
}

export const Org: IModelType<any, any> = types.model({
	id: types.identifierNumber,
	name: types.string,
	times: types.late(() => types.optional(types.array(TimeEntry), [])),
	areTimesLoaded: types.optional(types.boolean, false),
})
.actions(self => ({
	select() {
		const data: IDataModel = getParent(self, 2);
		data.clearSelectedClient();
		data.setSelectedOrg(self as any);

		const userId = data.session.id;
		Utils.save(`SELECTED_ORG:${userId}`, self.id);
	},
}))
.views(self => ({
	get clients() {
		const data: IDataModel = getParent(self, 2);
		const filteredClients = data.clients.filter((client: any) => client.oid === self);
		return filteredClients;
	},

	get projects() {
		const data: IDataModel = getParent(self, 2);
		return data.projects.filter((project: any) => project.oid === self);
	},

	get rootProjects() {
		const data: IDataModel = getParent(self, 2);
		const projectIds = data.projects.map((project: any) => project.id);
		return data.projects.filter((project: any) => project.pid === 0 || projectIds.indexOf(project.pid) === -1);
	},

	get users() {
		const data: IDataModel = getParent(self, 2);
		return data.users.filter((user: any) => user.oid === self);
	},

	get roles() {
		const data: IDataModel = getParent(self, 2);
		return data.roles.filter((role: any) => role.oid === self);
	},

	// TODO: sort clients by latest time entries
	// get clientsByActivity()

	// TODO: sort all relevant lists by latest time entries
	// get xyByActivity()
}))
.views(self => ({
	get clientCount() {
		return self.clients.length;
	},

	get projectCount() {
		return self.projects.length;
	},

	get timeCount() {
		return self.times.length;
	},

	get totalSeconds() {
		return self.times.reduce((acc, current) => acc + current.d, 0);
	}
}))
.actions(self => ({
	mergeTimes(newTimes: Array<any>, replace: boolean = false) {
		if (replace) {
			applySnapshot((self.times as any), newTimes);
		} else {
			for (const newTime of newTimes) {
				const id = newTime.id;
				const oldTime = self.times.find(time => time.id === id);

				if (oldTime != null) {
					applySnapshot(oldTime, newTime as any);
				} else {
					self.times.push(TimeEntry.create(newTime));
				}
			}
		}

		self.areTimesLoaded = true;
	},
}))
.actions(self => ({
	fetchTimes: flow(function* fetchTimes() {
		logger.log(`[ORG ${self.id}] fetching entries`);
		const startTS = Date.now();

		try {
			const entries = yield Api.getTimes({
				oid: self.id,
				cids: self.clients.map((client: any) => client.id),
				limit: DEFAULT_LIMIT,
				daystart: getFirstDayXMonthsAgo(2),
			});
			const runtimeApi = Date.now() - startTS;
			logger.warn("Runtime of api call: %d", runtimeApi);

			logger.log(`[Org ${self.id}] fetched entries:`, entries);
			logger.log(`[Org ${self.id}] merging entries`);

			const startMerge = Date.now();
			self.mergeTimes(entries as any, true);
			const runtimeMerge = Date.now() - startMerge;
			logger.warn("Runtime of merge: %d", runtimeMerge);

		} catch (err) {
			logger.error(`[Org ${self.id}] An error occured when fetching times.`, err);
			stores.userInterface.setError(err, {
				action: "models.org.fetchTimes",
				ts: Date.now(),
				oid: self.id,
				user: (getParent(self, 2) as IDataModel).session.id,
			});
		}
	}),
}));

export type IOrgModel = typeof Org.Type;
