import * as React from "react";
import { observer } from "mobx-react";
import * as Reactstrap from "reactstrap";

import Aux from "./Aux";
import Hoverable from "./Hoverable";
import { guid } from "../../lib/utils";

interface IPopoverProps {
	icon: string;
	className?: string;
	render: (close: () => void, toggle: () => void) => JSX.Element;
	renderHeader: (close: () => void, toggle: () => void) => JSX.Element;
	renderFooter?: (close: () => void, toggle: () => void) => JSX.Element;
	onOpen?: () => void;
}

interface IPopoverState {
	isOpen: boolean;
}

const RealPopover = Reactstrap.Popover as any;

@observer
export default class Popover extends React.Component<IPopoverProps, IPopoverState> {
	private id = "po-" + guid();
	private lastHidden = 0;

	constructor(props: IPopoverProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	public render() {
		const {
			props: {
				render,
				renderHeader,
				renderFooter,
				className,
				icon,
			},
			state: {
				isOpen,
			},
			id,
			close,
			toggle,
		} = this;

		return (
			<Aux>
				<Hoverable
					className={icon}
					hoveredClass="fa-spin"
					onClick={this.toggle}
					id={id}
				/>
				<RealPopover
					placement="left"
					isOpen={isOpen}
					toggle={this.toggle}
					target={id}
					className={className}
				>
					<Reactstrap.PopoverHeader>
						{renderHeader(close, toggle)}
					</Reactstrap.PopoverHeader>
					<Reactstrap.PopoverBody>
						{render(close, toggle)}
					</Reactstrap.PopoverBody>
					{renderFooter && (
						<Reactstrap.PopoverHeader>
							{renderFooter(close, toggle)}
						</Reactstrap.PopoverHeader>
					)}
				</RealPopover>
			</Aux>
		);
	}

	/**
	 * toggle the popover when it was closed at least 500ms ago
	 *
	 * @private
	 * @memberof Popover
	 */
	protected toggle = () => {
		const diff = Date.now() - this.lastHidden;

		if (diff < 500) {
			return;
		}

		const isOpen = !this.state.isOpen;
		const { onOpen } = this.props;

		if (isOpen && onOpen) {
			onOpen();
		}

		this.setState({isOpen});
	}

	/**
	 * close the popover and save the timestamp for internal use
	 *
	 * @private
	 * @memberof Popover
	 */
	protected close = () => {
		this.lastHidden = Date.now();
		this.setState({isOpen: false});
	}

	protected _handleKeyDown = (event: any) => {
		if (event.keyCode === 27) {
			this.close();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown);
	}
}
