import { BaseState } from "./_BaseState";
import { action, computed, observable, ObservableMap } from "mobx";
import { IBundle } from "../lib/interfaces";
import { Languages, TLanguage } from "../lib/languages";
import {
	InvalidCodePathError,
	MaxFetchRetriesError,
} from "../lib/errors";
import { sleep } from "../lib/utils";
import * as logger from "../lib/logger";
import * as moment from "moment";
import "moment/locale/de";
import { StateTree } from ".";

export class I18n extends BaseState {
	static maxTries = 3;

	@observable
	public isLoading = false;

	@observable
	private _bundle = new ObservableMap();

	@computed
	public get bundle() {
		return this._bundle.toJSON() as IBundle;
	}

	constructor(ref: StateTree) {
		super(ref);
		this.init();
	}

	@action
	public loadLang = async (langCode: TLanguage, tries = 0) => {
		// check if someone (me) was stupid
		// see possible values of `langCode`
		if (langCode === "prototype") {
			throw new InvalidCodePathError();
		}

		// don't retry endless
		if (tries >= I18n.maxTries) {
			// TODO: global error handling
			throw new MaxFetchRetriesError("language bundle", langCode);
		}

		try {
			// fetch and import the language bundle
			this.isLoading = true;
			const bundle = await Languages[langCode]();
			this.setBundle(bundle);
			localStorage.setItem("lang", langCode); // save langCode in localStorage for persistance
			moment.locale(langCode.substr(2));
		} catch (err) {
			// retry with exponential backoff
			const backoffTime = Math.pow(1.5, tries + 1);
			await sleep(backoffTime * 1000);
			await this.loadLang(langCode, tries + 1);
		} finally {
			this.isLoading = false;
		}
	}

	@action
	private init = async () => {
		const langCode = localStorage.getItem("lang"); // try to load existing langCode

		if (langCode) {
			const bundle = await Languages[langCode]();
			this.setBundle(bundle);
			moment.locale(langCode.substr(2));
		} else {
			const bundle = await Languages.de_DE();
			this.setBundle(bundle);
			moment.locale("de");
		}

		// uncomment this to test loading split bundles
		// setTimeout(async () => {
		// 	const bundle = await Languages.en_US();
		// 	this.setBundle(bundle);
		// }, 3000);
	}

	@action
	private setBundle = (bundle: IBundle) => {
		logger.log("[I18N] setting i18n bundle", bundle);

		this._bundle.clear();
		this._bundle.merge(bundle);
	}
}
