import { BaseState } from "./_BaseState";
import { action, computed, observable, toJS } from "mobx";

import { LOGIN_STATE } from "../lib/enums";
import * as logger from "../lib/logger";

export class UserInterface extends BaseState {
	@observable
	public errorData = {
		meta: null,
		err: null,
	} as {
		meta?: any;
		err?: any;
	};

	@observable
	public loginState = LOGIN_STATE.INITIAL;

	@observable isBootstrapping = true;

	@computed
	public get isLoggedIn(): boolean {
		return this.loginState === LOGIN_STATE.LOGGED_IN;
	}

	@computed
	public get hasError(): boolean {
		return this.errorData.err != null;
	}

	@action
	nextLoginState = () => {
		let next = this.loginState + 1;

		if (LOGIN_STATE[next] != null) {
			this.loginState = next;
		} else {
			this.loginState = LOGIN_STATE.INITIAL;
		}
	}

	@action
	setLoggedIn = () => {
		this.loginState = LOGIN_STATE.LOGGED_IN;
	}

	@action
	setError = (err: any, meta?: any) => {
		this.errorData.err = err;
		this.errorData.meta = meta;

		const errorData = toJS(this.errorData);

		errorData.err = JSON.parse(JSON.stringify(errorData.err, Object.getOwnPropertyNames(errorData.err)));
		errorData.meta = JSON.parse(JSON.stringify(errorData.meta, Object.getOwnPropertyNames(errorData.meta)));
		const toApi = JSON.stringify(errorData);

		logger.error("TODO: SEND THIS TO THE API: %s", toApi);
	}

	@action
	setIsBootstrapping = (isBootstrapping: boolean) => {
		this.isBootstrapping = isBootstrapping;
	}
}
