import * as React from "react";
import { secs2Hours } from "../../../lib/utils";
import Chart from "react-apexcharts";

export interface IPieChartDataPoint {
	meta: string;
	value: number;
}
export interface IPieChartProps {
	data: {
		labels: string[];
		series: number[];
	};
}

export default class PieChart extends React.Component<IPieChartProps, {}> {
	public render() {
		return (
			<Chart
				options={{
					chart: {
						type: "pie"
					},
					labels: this.props.data.labels,
					colors: ["#3D78AB", "#6393BB", "#9EBBD5", "#C4D6E5", "#eeeeee"],
					yaxis: {
						labels: {
							formatter: function (value: number) {
								return secs2Hours(value) + "h";
							}
						}
					},
					dataLabels: {
						enabled: false
					},
					legend: {
						show: false
					},
					tooltip: {
						custom: function({series, seriesIndex, dataPointIndex, w}: any) {
							return `<div class="custom-tooltip">${w.globals.labels[seriesIndex]}: ${secs2Hours(series[seriesIndex])}h</div>`;
						}
					},
				}}
				height="180"
				series={this.props.data.series}
				type="pie"
			/>
		);
	}
}
