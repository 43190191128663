import * as React from "react";
import { text } from "../../../lib/i18n";
// import { Aux } from "../../../components/Aux";

const spinnerIcon = require("../../../images/icons/spinner.svg");

export default class LoggingIn extends React.Component<{}, {}> {
	render() {
		return (
			<div className="icon-wrapper">
				<img
					src={spinnerIcon}
					className="icon icon-spinner"
				/>
				<span className="space-top">
					{text("login.waitForLogin")}
				</span>
			</div>
		);
	}
}
