import * as React from "react";

const spinnerIcon = require("../../images/icons/spinner.svg");

interface IFadingSpinnerProps {
	show: boolean;
	type: "client"|"login";
}

export default class FadingSpinner extends React.Component<IFadingSpinnerProps, {}> {
	public render() {
		const {
			show,
			type,
		} = this.props;

		return (
			<div
				className={`d-flex justify-content-center spinner${!show ? " hide" : ""}`}
			>
				<img
					className={type === "client" ? "client" : "login"}
					src={spinnerIcon}
				/>
			</div>
		);
	}
}
