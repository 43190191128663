import * as React from "react";
import { observer } from "mobx-react";

// import { getFirstDateXMonthsAgo } from "../../../../lib/utils";

import { IProject } from "../../../../stores/models/Project";
import { IClientModel } from "../../../../stores/models/Client";
import HeatMap from "../../../common/charts/HeatMap";
// import BarChart from "../../../common/charts/BarChart";
import { secs2HoursFixed } from "../../../../lib/utils";
import { text } from "../../../../lib/i18n";

interface IClientCardProps {
	client: IClientModel;
	setSelectedClient: (client: IClientModel) => void;
}

interface ITopProjectProps {
	data: IProject;
}

@observer
export class TopProject extends React.Component<ITopProjectProps, {}> {
	public render() {
		const { name, totalSecondsInLastThreeMonths } = this.props.data;

		return (
			<tr>
				<td className="text-truncate">{name}</td>
				<td className="text-right">{secs2HoursFixed(totalSecondsInLastThreeMonths)}h</td>
			</tr>
		);
	}
}
@observer
export default class ClientCard extends React.Component<IClientCardProps, {}> {
	public render() {
		const { client } = this.props;

		return (
			<div className="client-card px-4 border-top">
				<div className="row mb-3 bg-light mx-n4 px-2">
					<div className="col d-flex justify-content-between py-1">
						<h2 className="my-auto">{client.name}</h2>

					</div>
				</div>
				<div className="row mb-4">
					<div className="col-lg-6 col-md-12">
						<div className="row mb-1">
							<div className="col">
								<h3>{text("clientCard.overview")}</h3>
							</div>
						</div>
						<div className="row">
							<div className="col d-flex justify-content-center">
								<HeatMap
									data={client.heatmapData}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="row mb-1">
							<div className="col">
								<h3>{text("clientCard.topProjects", 3)}</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<table className="table table-sm table-striped">
									<colgroup>
										<col style={{ width: "75%" }} />
										<col style={{ width: "25%" }} />
									</colgroup>
									<tbody>

										{client.topRootProjects.length
											? client.topRootProjects.map((project: IProject) => (
												<TopProject key={project.id} data={project} />
											))
											: (
												<tr>
													<td colSpan={2}>
														{text("clientCard.emptyTopProjects", 3)}
													</td>
												</tr>
											)
										}
									</tbody>
								</table>
							</div>

							<div className="col-12 text-center">
								<button
									className="btn btn-primary"
									onClick={this.showDetails}
								>
									{text("clientCard.detailBtn")}
								</button>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}

	private showDetails = () => {
		const { setSelectedClient, client } = this.props;
		client.fetchAvailableMonths();
		setSelectedClient(client);
	}
}
