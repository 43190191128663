import * as React from "react";
import Chart from "react-apexcharts";
import { getChartLabelFromMMYY } from "../../../lib/utils";
import { text } from "src/lib/i18n";

interface IBarChartProps {
	data: {
		labels: string[];
		series: number[];
	};

	settings: {
		height: number;
	};
}

export default class BarChart extends React.Component<IBarChartProps, {}> {
	public render() {
		return (
			<Chart
				options={{
					colors: ["#3D78AB", "#6393BB", "#9EBBD5", "#C4D6E5", "#eeeeee"],
					xaxis: {
						categories: this.props.data.labels,
						labels: {
							formatter: function (value: string, _: any, idx: number) {
								if (idx % 2 !== 0) {
									return getChartLabelFromMMYY(value);
								}

								return "";
							}
						},
					},
					dataLabels: {
						enabled: false
					},
					tooltip: {
						x: {
							show: true
						},
						enabled: true,
						custom: function({series, seriesIndex, dataPointIndex, w}: any) {
							return `<div class="d-flex flex-column custom-tooltip">${getChartLabelFromMMYY(w.globals.labels[dataPointIndex])}<div class="justify-content-end text-right">${series[seriesIndex][dataPointIndex]}h</div></div>`;
						}
					},
					chart: {
						toolbar: {
							show: false
						}
					},
					yaxis: {
						show: false
					}
				}}
				height={this.props.settings.height}
				series={[{
					name: text("clientDetails.charts.hours"),
					data: this.props.data.series
				}]}
				type="bar"
			/>
		);
	}
}
