import * as React from "react";
import * as logger from "../../lib/logger";
import { text } from "../../lib/i18n";

const snapImage: string = require("../../images/oh-snap.jpg");

interface IErrorBoundaryProps {
	children: JSX.Element|JSX.Element[]|null;
}

interface IErrorBoundaryState {
	error: any|null;
	errorInfo: any|null;
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props: IErrorBoundaryProps, state: IErrorBoundaryState) {
		super(props, state);

		this.state = {
			error: null,
			errorInfo: null,
		};
	}

	public componentDidCatch(error: any, errorInfo: any) {
		logger.error("An error has been catched:", error, errorInfo);
		this.setState({ error, errorInfo });
	}

	public render() {
		const { error } = this.state;

		if (error != null) {
			return (
				<div
					className="snap"
					onClick={() => logger.log("clicked on error boundary")}
				>
					<img src={snapImage} />
					<p>{text("errors.message")}</p>
				</div>
			);
		} else {
			return this.props.children;
		}
	}
}
