import * as React from "react";
import {
	Route,
	Switch,
} from "react-router-dom";

import stores from "../stores";

import Frame from "./common/Frame";
import DynamicRouter from "./common/utils/DynamicRouter";
import AuthRoute from "./common/AuthRoute";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import LoginLandingScreen from "./screens/LoginLandingScreen/index";

/**
 * App routing is defined here.
 * CAUTION: don't make this component an observer...
 * react-router's switch and observers don't mix well
 *
 * @export
 * @class App
 * @extends {React.Component}
 */
export default class App extends React.Component {
	render() {
		return (
			<DynamicRouter
				useUrls={true}
				syncWithStore={stores.syncRouterStore}
			>
				<Frame>
					<Switch>
						<Route exact={true} path="/login" component={LoginScreen} />
						<Route exact={true} path="/login/:status" component={LoginLandingScreen} />
						<AuthRoute path="/" component={HomeScreen} />
					</Switch>
				</Frame>
			</DynamicRouter>
		);
	}
}
