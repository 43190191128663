import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

interface IHoverableProps {
	className: string;
	hoveredClass: string;
	[propName: string]: any;
}

@observer
export default class Hoverable extends React.Component<IHoverableProps, {}> {
	@observable hovered = false;

	public render() {
		const {
			children,
			className,
			hoveredClass,
			...rest
		} = this.props;

		const props = {
			className: className + " " + (this.hovered ? hoveredClass : ""),
			onMouseEnter: this.onMouseEnter,
			onMouseLeave: this.onMouseLeave,
			...rest
		};

		return <span {...props}>{children}</span>;
	}

	private onMouseEnter = () => {
		this.hovered = true;
	}

	private onMouseLeave = () => {
		this.hovered = false;
	}
}
