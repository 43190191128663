import { IDataModel } from "./models/Data";
import { action, observable, computed } from "mobx";
import { UserInterface } from "./UserInterface";
import { I18n } from "./I18n";
import { Config } from "./Config";
import { History } from "history";
import {
	RouterStore,
	syncHistoryWithStore,
} from "mobx-react-router";

export class StateTree {
	@observable
	public config: Config;

	@observable
	public userInterface: UserInterface;

	@observable
	public i18n: I18n;

	@observable
	public data: IDataModel;

	@observable
	public routerStore: RouterStore;

	@computed
	public get isOnLoginPage() {
		const { location } = this.routerStore;
		return location != null && location.pathname.startsWith("/login");
	}

	@computed
	public get isOnLoginLandingPage() {
		const { location } = this.routerStore;
		return location != null && (
			location.pathname.startsWith("/login/success")
			|| location.pathname.startsWith("/login/fail")
		);
	}

	constructor() {
		this.config = new Config(this);
		this.i18n = new I18n(this);
		this.userInterface = new UserInterface(this);
		this.routerStore = new RouterStore();
	}

	@action
	setData(data: IDataModel) {
		this.data = data;
	}

	@action
	syncRouterStore = (history: History) => {
		syncHistoryWithStore(history, this.routerStore);
	}
}

const stores = new StateTree();
export default stores;

// attach stores to global window object
// in development and testing
if (process.env.NODE_ENV !== "production") {
	(window as any).stores = stores;
}
