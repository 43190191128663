import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import * as logger from "../../lib/logger";
import stores from "../../stores";
const { userInterface } = stores;

interface IAuthRouteProps {
	component?: JSX.Element|React.Component|any; // huh?  only works with any =(
	render?: any;
	[propName: string]: any;
}

/**
 * HoC: A route that checks user login before rendering.
 * Logged out users will be redirected to /login
 *
 * @export
 * @class AuthRoute
 * @extends {React.Component<IAuthRouteProps, {}>}
 */
export default class AuthRoute extends React.Component<IAuthRouteProps, {}> {
	public render() {
		const {
			component: Component,
			render,
			...rest
		} = this.props;

		return (
			<Route
				{...rest}
				render={props => {
					if (process.env.NODE_ENV === "development") {
						logger.log(`[AUTHROUTE]: Navigating to ${props.match.path}`);
					}

					// don't redirect when we are in dev mode and `#no-login` is appended to the url
					if (!userInterface.isLoggedIn && !(process.env.NODE_ENV === "development" && location.hash === "#no-login")) {
						// user is NOT logged in
						// redirect to:
						const target = "/login";

						if (process.env.NODE_ENV === "development") {
							logger.log(`[AUTHROUTE]: Not authorized; redirecting to "${target}"`);
						}

						return <Redirect to={target} />;

					} else {
						// user is logged in
						// continue rendering

						if (process.env.NODE_ENV === "development") {
							logger.log("[AUTHROUTE]: Logged in; rendering");
						}

						return render
							? render(props)
							: <Component {...props} />;
					}
				}}
			/>
		);
	}
}
