import * as React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import stores from "../../stores";
// import * as logger from "../../lib/logger";

interface IBreadcrumbsProps {
	history?: any;
}

@observer
class Breadcrumbs extends React.Component<IBreadcrumbsProps, {}> {
	public render() {
		const hasOrg = !!(stores.data && stores.data.selectedOrg);
		const atClient = !!(stores.data && stores.data.selectedClient);
		const atOrg = !atClient && hasOrg;

		return (
			<div className="col">
				<nav aria-label="breadcrumb" role="navigation">
					<ol className="breadcrumb">
						{stores.data && stores.data.selectedOrg && (
							<li
								className={`breadcrumb-item ${atOrg ? "active" : ""}`}
								onClick={stores.data && stores.data.clearSelectedClient}
							>
								{atOrg ? (
									stores.data.selectedOrg.name
								) : (
									<a href="#">
										{stores.data.selectedOrg.name}
									</a>
								)}
							</li>
						)}

						{atClient && (
							<li className="breadcrumb-item active">
								{stores.data.selectedClient.name}
							</li>
						)}
					</ol>
				</nav>
			</div>
		);
	}
}
export default withRouter(Breadcrumbs as any);
