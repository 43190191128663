export class NotImplementedError extends Error {
	constructor(feature?: string) {
		super(`feature '${feature}' not implemented!`);

		// this is needed so
		// `(err instanceof NotImplementedError) === true`
		Object.setPrototypeOf(this, NotImplementedError.prototype);
	}
}

export class InvalidCodePathError extends Error {
	constructor() {
		super("This code path should NEVER be executed!");

		// this is needed so
		// `(err instanceof InvalidCodePathError) === true`
		Object.setPrototypeOf(this, InvalidCodePathError.prototype);
	}
}

export class HttpError extends Error {
	public response: Response;

	constructor(response: Response) {
		super(response.statusText);

		// this is needed so
		// `(err instanceof HttpError) === true`
		Object.setPrototypeOf(this, HttpError.prototype);

		this.response = response;
	}
}

export class MaxFetchRetriesError extends Error {
	constructor(resourceType: string, name: string) {
		super(`Could not load ${resourceType}: ${name}`);
	}
}
