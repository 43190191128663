import { types } from "mobx-state-tree";

export const Session = types.model("Session", {
	id: types.identifier,
	r: types.number,
	w: types.number,
	ttl: types.number,
	idle: types.number,
	ip: types.string,
});

export type ISessionModel = typeof Session.Type;
