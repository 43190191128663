import { IClient } from "../../stores/models/Client";
import * as React from "react";
import * as moment from "moment";
import { observer } from "mobx-react";
import { Collapse } from "reactstrap";
import { observable } from "mobx";

import Aux from "./Aux";

import * as logger from "../../lib/logger";
// import { guid } from "../../lib/utils";
import Popover from "./Popover";
import { text } from "../../lib/i18n";

interface IMonthRowProps {
	month: number;
	onMonthClick: (month: number) => void;
	disabled: boolean;
	active: boolean;
}

interface ICollapsibleYearProps {
	year: {
		year: number;
		months: number[];
	};
	openYear: number;
	setMonth: (year: number, month: number) => void;
	setOpenYear: (year: number) => void;
	activeMonth: number;
	activeYear: number;
}

interface IPopoverMonthPickerProps {
	icon: string;
	label: string;
	placeholder?: string;
	activeMonthAndYear: {
		month: number;
		year: number;
	};
	onChange: (year: number, month: number) => void;
	client: IClient;
}

class MonthRow extends React.Component<IMonthRowProps, {}> {
	public render() {
		const {
			props: {
				month,
				disabled,
				active,
			},
			handleClick,
		} = this;

		return (
			<>
			{disabled
				? (null)
				: (<tr
					className={`month ${active ? "table-active" : ""} ${disabled ? "disabled" : ""}`}
					onClick={disabled ? undefined : handleClick}
				>
					<td className="text-truncate">{moment(month, "M").format("MMMM")}</td>
				</tr>)}
			</>

		);
	}

	private handleClick = () => {
		const { month, onMonthClick } = this.props;
		onMonthClick(month);
	}
}

@observer
class CollapsibleYear extends React.Component<ICollapsibleYearProps, {}> {
	public render() {
		const {
			props: {
				year,
				openYear,
				activeMonth,
				activeYear,
			},
			handleYearClick,
			handleMonthClick,
		} = this;

		const isOpen = openYear === year.year;

		return (
			<Aux>
				<tr
					onClick={handleYearClick}
					className="year"
				>
					<td>
						<span className={`arrow-wrp arrow-t ${isOpen ? "open" : ""}`} />
						<span className="content">{year.year}</span>
					</td>
				</tr>
				<Collapse
					isOpen={isOpen}
				>
					<table className="table table-sm">
						<tbody>
							{[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(month => (
								<MonthRow
									key={month}
									month={month}
									onMonthClick={handleMonthClick}
									active={activeYear === year.year && activeMonth === month}
									disabled={year.months.indexOf(month) === -1}
								/>
							))}
						</tbody>
					</table>
				</Collapse>
			</Aux>
		);
	}

	private handleYearClick = () => {
		const { setOpenYear, year, openYear } = this.props;
		const isOpen = openYear === year.year;
		logger.log(isOpen, year, openYear);
		setOpenYear(isOpen ? 0 : year.year - 2000);
	}

	private handleMonthClick = (month: number) => {
		const { setMonth, year } = this.props;
		setMonth(year.year - 2000, month);
	}
}

@observer
export default class PopoverMonthPicker extends React.Component<IPopoverMonthPickerProps, {}> {
	@observable
	private openYear: number;

	constructor(props: IPopoverMonthPickerProps) {
		super(props);

		this.openYear = this.props.activeMonthAndYear.year;
	}

	public render() {
		const {
			props: {
				label,
				activeMonthAndYear,
				client,
				icon,
			},
			openYear,
			setOpenYear,
			setMonth,
			reset,
		} = this;

		const isCurrentMonth = activeMonthAndYear.year === parseInt(moment().format("YY"), 10)
			&& activeMonthAndYear.month === parseInt(moment().format("M"), 10);

		return (
			<Popover
				icon={icon}
				renderHeader={(close) => (
					<Aux>
						{label}
						<button
							type="button"
							className="close"
							aria-label="Close"
							onClick={close}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</Aux>
				)}
				onOpen={client.fetchAvailableMonths}
				render={(close) => client.isLoadingAvailableMonths ? (
					<div>
						<hr />
						{text("loading")}
						<hr />
					</div>
				) : (
					<table
						className="month-picker table table-sm"
						ref={domEl => domEl && domEl.focus()}
					>
						<tbody>
							{client.availableMonthsByYear.map((year, index) => (
								<CollapsibleYear
									key={year.year}
									year={year}
									openYear={2000 + openYear}
									activeYear={2000 + activeMonthAndYear.year}
									activeMonth={activeMonthAndYear.month}
									setMonth={setMonth(close)}
									setOpenYear={setOpenYear}
								/>
							))}
						</tbody>
					</table>
				)}
				renderFooter={(close) => (
					<Aux>
						<button
							style={{display: isCurrentMonth ? "none" : "unset"}}
							className="btn btn-sm btn-block btn-primary"
							disabled={isCurrentMonth}
							onClick={!isCurrentMonth ? reset(close) : undefined}
						>
							<span>{text("clientDetails.filters.resetMonth")}</span>
						</button>
					</Aux>
				)}
			/>
		);
	}

	private setOpenYear = (year: number) => {
		this.openYear = year;
	}

	private setMonth = (close: () => void) => (year: number, month: number) => {
		logger.log("[PopoverMonthPicker] selected yearmonth: %d%d", year, month);
		this.props.onChange(year, month);
		close();
	}

	private reset = (close: () => void) => () => {
		const currentMonth = parseInt(moment().format("M"), 10);
		const currentYear = parseInt(moment().format("YY"), 10);
		this.setMonth(close)(currentYear, currentMonth);
	}
}
