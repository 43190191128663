import * as React from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";

import { login } from "../../../lib/actions/session";
import { text } from "../../../lib/i18n";

@observer
export default class InputForm extends React.Component<{}, {}> {
	// tslint:disable-next-line:max-line-length
	static mailRegex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9][-a-z0-9]*(\.[-a-z0-9]+)*\.([a-z][a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

	private emailInputElem: HTMLInputElement|null;

	private captchaDivElem: HTMLDivElement|null;
	private captchaInputElem: HTMLInputElement|null;
	private captchaScript: HTMLScriptElement|null;

	@observable
	private email = "";

	@observable
	private captcha = "";

	@observable
	private captchaToken = "";

	@observable
	private step = 0;

	@computed
	private get isValidCaptcha(): boolean {
		return this.captcha.length > 0;
	}

	@computed
	private get isValidEmail(): boolean {
		return InputForm.mailRegex.test(this.email);
	}

	@computed
	private get isValidForm(): boolean {
		return this.isValidCaptcha && this.isValidEmail;
	}

	public render() {
		return (
			<div>
				{this.step === 0 &&
					<>
						<div className="input">
							<input
								type="email"
								className="form-control"
								placeholder={text("login.emailPlaceholder")}
								autoComplete="email"
								required={true}
								autoFocus={true}
								ref={elem => this.emailInputElem = elem}
								value={this.email}
								onChange={this.updateEmail}
								onKeyUp={this.keyUpEvent}
								tabIndex={1}
							/>
						</div>
						<div className="input">
							<button
								className="btn btn-lg btn-primary btn-block btn-signin"
								type="submit"
								disabled={!this.isValidEmail}
								onClick={this.nextStep}
							>
								{text("login.nextStep")}
							</button>
						</div>
					</>
				}

				{this.step === 1 &&
					<>
						<i className="fa fa-arrow-left pr-2" onClick={this.previousStep} />{text("login.back")}
						<div className="d-flex align-items-center justify-content-center my-3">
							<div
								className="input recaptcha h-100"
								ref={elem => this.captchaDivElem = elem}
							>
								{text("login.loadingCaptcha")}
							</div>
							<i className="fa fa-refresh fa-2x ml-3" title={text("login.refresh")} onClick={this.createCaptcha} />
						</div>
						<input
							type="text"
							className="form-control"
							required={true}
							autoFocus={true}
							ref={elem => this.captchaInputElem = elem}
							value={this.captcha}
							onChange={this.updateCaptcha}
							onKeyUp={this.keyUpEvent}
							tabIndex={1}
						/>
						<div className="input">
							<button
								className="btn btn-lg btn-primary btn-block btn-signin"
								type="submit"
								disabled={!this.isValidForm}
								onClick={this.handleSubmit}
							>
								{text("login.loginButton")}
							</button>
						</div>
					</>
				}
			</div>
		);
	}

	private keyUpEvent = (e: any) => {
		if (e.keyCode === 13) {
			if (this.step === 0) {
				if (this.isValidEmail) {
					this.nextStep();
				}
			} else if (this.step === 1) {
				if (this.isValidForm) {
					this.handleSubmit();
				}
			}
		}
	}

	private nextStep = () => {
		this.step++;

		if (this.step === 1) {
			this.createCaptcha();
		}
	}

	private previousStep = () => {
		if (this.step === 0) {
			return;
		}

		this.step--;
	}

	private handleSubmit = async () => {
		await login(this.email, this.captcha, this.captchaToken);
	}

	/**
	 * save typed email address
	 *
	 * @private
	 * @memberof InputForm
	 */
	private updateEmail = () => {
		// unnecessary null check... thank you typing
		if (this.emailInputElem != null) {
			this.email = this.emailInputElem.value;
		}
	}

	private updateCaptcha = () => {
		if (this.captchaInputElem) {
			this.captcha = this.captchaInputElem.value;
		}
	}

	private createCaptcha = () => {
		// only create function once
		if (!this.captchaScript) {
			// @ts-ignore
			window.APPcreateCaptcha = (things: any) => {
				if (this.captchaDivElem) {
					this.captchaDivElem.innerHTML = things.data;
				}

				this.captchaToken = things.token;
			};
		}

		// remove old script if it already exists
		if (this.captchaScript) {
			document.getElementsByTagName("head")[0].removeChild(this.captchaScript);
		}

		this.captchaScript = document.createElement("script");
		this.captchaScript.src = "https://www.webmart.de/captcha/create?callback=APPcreateCaptcha";
		this.captchaScript.type = "text/javascript";
		document.getElementsByTagName("head")[0].appendChild(this.captchaScript);
	}
}
