import { get } from "lodash";
import stores from "../stores";

/**
 * Our i18n text function.
 * Give it an i18n key plus optional values and you get back a translated string
 * CAUTON: React Components using this function MUST be observers
 *
 * @export
 * @param {string} key
 * @param {...any[]} values
 * @returns
 */
export function text(key: string, ...values: any[]) {
	const localizedText: any = get(stores.i18n.bundle, key);
	return format(localizedText, key, ...values);
}

const placeholderRegex = /%[%sd]/g;

function format(str: string, key: string, ...values: any[]): string {
	if (typeof str !== "string") {
		return `EI18NKEY: ${key} `;
	}

	let i = 0;

	return str.replace(placeholderRegex, function(match: string) {
		if (i === values.length) {
			return "EI18NVAL";
		}

		switch (match) {
			case "%%":
				return "%";
			case "%s":
			case "%d": // TODO decimals formatting
				return String(values[i++]);
			default:
				return match;
		}
	});
}
