import * as React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

import * as logger from "../../../../lib/logger";
import { IProject } from "../../../../stores/models/Project";
import { IFilter } from "../../../../stores/models/Filter";
import SubProjectRow from "./SubProjectRow";
import { secs2HoursFixed } from "../../../../lib/utils";

interface IRootProjectRowProps {
	project: IProject;
	filter: IFilter;
}

@observer
export default class RootProjectRow extends React.Component<IRootProjectRowProps, {}> {
	@observable
	private isOpen = false;

	public render() {
		const {
			project: {
				id,
				name,
				secondsByFilter,
				totalSecondsByFilter,
				subProjectsByFilterAndAlpha,
			},
			filter,
		} = this.props;

		const {
			isOpen,
			handleClick,
		} = this;

		const subProjects = subProjectsByFilterAndAlpha(filter);
		const hasSubProjects = subProjects.length > 0;
		const hasSPAndIsOpen = hasSubProjects && isOpen;

		const filteredSeconds = secondsByFilter(filter);
		const totalFilteredSeconds = totalSecondsByFilter(filter);

		return ([
			(
				<tr
					className="root-project"
					key={id}
					onClick={hasSubProjects ? handleClick : undefined}
				>
					<td className="text-truncate name">
						<span className={`arrow-wrp ${hasSubProjects ? "arrow-t" : ""} ${hasSPAndIsOpen ? "open" : ""}`} />
						<span className="content">{name}</span>
					</td>
					<td className="text-right hours">
						{secs2HoursFixed(hasSPAndIsOpen ? filteredSeconds : totalFilteredSeconds)}h
					</td>
				</tr>
			),

			(hasSubProjects && isOpen) ? (
				subProjects.map(subProject => (
					<SubProjectRow
						key={subProject.id}
						project={subProject}
						filter={filter}
					/>
				))
			) : null
		]);
	}

	@action
	private handleClick = () => {
		logger.log("clicked on row", this.props.project.name);
		this.isOpen = !this.isOpen;
	}
}
