import * as React from "react";
import { observer } from "mobx-react";
// import { withRouter } from "react-router-dom";

import stores from "../../../../stores";
import { IOrgModel } from "../../../../stores/models/Org";
import ClientCard from "./ClientCard";
import FadingSpinner from "../../../common/FadingSpinner";
import { IClientModel, IClient } from "../../../../stores/models/Client";
import ClientDetails from "./ClientDetails";

// import { text } from "../../../../lib/i18n";

@observer
export default class Main extends React.Component<{}, {}> {
	public render() {
		const {
			data: {
				selectedOrg: _selectedOrg,
				selectedClient: _selectedClient,
				setSelectedClient,
				clearSelectedClient
			},
		} = stores;

		const selectedOrg = _selectedOrg as IOrgModel;
		const selectedClient = _selectedClient as IClientModel;

		return (
			<div className="view client-cards">
				<FadingSpinner
					type="client"
					show={!selectedOrg.areTimesLoaded}
				/>
				{!selectedClient && selectedOrg.clients && selectedOrg.clients.map((client: IClient) => (
					<ClientCard
						key={client.id}
						client={client}
						setSelectedClient={setSelectedClient}
					/>
				))}
				{selectedClient && (
					<ClientDetails
						client={selectedClient}
						clearSelectedClient={clearSelectedClient}
					/>
				)}
			</div>
		);
	}
}
