import * as React from "react";
import { observer } from "mobx-react";

import { IClient } from "../../../../stores/models/Client";
import { secs2HoursFixed } from "../../../../lib/utils";
import RootProjectRow from "./RootProjectRow";
import { text } from "../../../../lib/i18n";

interface IProjectTableProps {
	client: IClient;
}

@observer
export default class ProjectTable extends React.Component<IProjectTableProps, {}> {
	public render() {
		const {
			client: {
				filter,
				filteredRootProjectsByAlpha,
				filteredTotalSeconds,
				isLoadingTimes,
			},
		} = this.props;

		return (
			<table className={`table table-sm table-hover ${isLoadingTimes ? "loading" : ""}`}>
				<thead>
					<tr className="text-uppercase">
						<th scope="col" >{text("clientDetails.table.headings.project")}</th>
						<th scope="col" className="text-right">{text("clientDetails.table.headings.hours")}</th>
					</tr>
				</thead>
				<tbody>
					{filteredRootProjectsByAlpha.length
					? (
						[
							filteredRootProjectsByAlpha.map(rootProject => (
								<RootProjectRow
									key={rootProject.id}
									project={rootProject}
									filter={filter}
								/>
							))
						,
							<tr
								key={1}
							>
								<td className="text-truncate">
									<span className="arrow-wrp" />
									{text("clientDetails.table.headings.sum")}
								</td>
								<td className="text-right">
									<b>{secs2HoursFixed(filteredTotalSeconds)}h</b>
								</td>
							</tr>
						]
					) : (
						<tr className="empty">
							<td colSpan={2}>{text("clientDetails.table.empty")}</td>
						</tr>
					)}
				</tbody>
			</table>
		);
	}
}
