import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";

import { text } from "../../../lib/i18n";

interface ILoginLandingScreenProps extends RouteComponentProps<any>, React.Props<any> {

}

@observer
export default class LoginLandingScreen extends React.Component<ILoginLandingScreenProps, {}> {
	public render() {
		const {
			match: {
				params: {
					status,
				}
			}
		} = this.props;

		const isSuccess = status === "success";

		return (
			<div className="login-landing row justify-content-center align-items-center pb-5">
				<div className="col-5 justify-content-center">
					<div className="row justify-content-center mb-5">
						<div className="col text-center">
							<img
								className={`result-icon ${isSuccess ? "success" : "fail"}`}
								src={isSuccess
									? require("../../../images/icons/checkmark.svg")
									: require("../../../images/icons/blocked.svg")}
								width={100}
								height={100}
							/>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col text-center">
							{isSuccess
								? (
									<span>{text("login.landing.success")}</span>
								) : (
									<span>{text("login.landing.failed")}</span>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
