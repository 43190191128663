export interface IHasName {
	name: string;
}

export interface IHasYear {
	year: number;
}

export function byAlpha(a: IHasName, b: IHasName): number {
	return a.name.toLowerCase().localeCompare(
		b.name.toLowerCase()
	);
}

export function byYearDesc(a: IHasYear, b: IHasYear): number {
	return b.year - a.year;
}

/**
 * sort sort entries by duration
 * numeric descending
 *
 * @export
 * @template T
 * @param {[T, number]} a
 * @param {[T, number]} b
 * @returns {number}
 */
export function entriesByDuration<T>(a: [T, number], b: [T, number]): number {
	return b[1] - a[1];
}
