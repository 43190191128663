import * as React from "react";
import { observer } from "mobx-react";

import InnerView from "./InnerView";
import { text } from "../../../lib/i18n";
import FadingSpinner from "../../common/FadingSpinner";
import stores from "../../../stores";

@observer
class LoginScreen extends React.Component<{}, {}> {
	render() {
		return (
			<div className="login-screen">
				<FadingSpinner
					type="login"
					show={stores.userInterface.isBootstrapping}
				/>
				<InnerView />

				<div className="footer">
					<a href="https://www.webmart.de">WebMart.de</a>
					&nbsp;-&nbsp;
					<a href="https://www.webmart.de/web/impressum/">{text("imprintLink")}</a>
				</div>
			</div>
		);
	}
}

export default LoginScreen;
