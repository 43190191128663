import * as React from "react";
import { observer } from "mobx-react";
import Popover from "./Popover";
import "react-select/dist/react-select.css";
import { IFilter } from "../../stores/models/Filter";
import { IHasNameAndId } from "../../lib/interfaces";

import * as logger from "../../lib/logger";
import { Aux } from "./Aux";
import { text } from "../../lib/i18n";

interface IPopoverProps<T extends IHasNameAndId> {
	icon: string;
	label: string;
	className?: string;
	filter: IFilter;
	items: T[];
	getSelected: (filter: IFilter) => T[];
	setSelected: (selectedIds: number[]) => void;
}

@observer
export default class PopoverSelect<T extends IHasNameAndId> extends React.Component<IPopoverProps<T>> {
	private domEl: HTMLDivElement;

	public render() {
		const {
			props: {
				label,
				className,
				items,
				getSelected,
				filter,
				icon,
			},
			handleChange,
			handleClear,
		} = this;

		const selectedItems = getSelected(filter);

		// logger.log("[PopoverSelect] selectedItems", (selectedItems as any).toJSON(), (filter.pids as any).toJSON());

		return (
			<Popover
				icon={icon}
				className={className}
				renderHeader={(close) => (
					<Aux>
						{label}
						<button
							type="button"
							className="close"
							aria-label="Close"
							onClick={close}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</Aux>
				)}
				render={() => (
					<Aux>
						<div
							ref={domEl => domEl && (this.domEl = domEl) && domEl.focus()}
							className="popover-select list-group"
						>
							{items.map(item => {
								// logger.log(item.name, item.id, typeof item.id, selectedItems.indexOf(item.id as any) > -1);
								return (
									<label
										key={item.id}
										className="list-group-item"
										title={item.name}
									>
										<input
											type="checkbox"
											value={item.id}
											onChange={handleChange}
											checked={selectedItems.indexOf(item.id as any) > -1}
										/>
										&nbsp;
										{item.name}
									</label>
								);
							})}
						</div>
					</Aux>
				)}
				renderFooter={(close) => (
					<Aux>
						<button
							style={{
								display: selectedItems.length <= 0 ? "none" : "unset",
							}}
							className="btn btn-sm btn-block btn-primary"
							disabled={selectedItems.length <= 0}
							onClick={selectedItems.length > 0 ? () => {
								handleClear();
								close();
							} : undefined}
						>
							<span>{text("clientDetails.filters.resetSelect")}</span>
						</button>
					</Aux>
				)}
			/>
		);
	}

	/**
	 * handle selection changes
	 *
	 * @private
	 * @memberof PopoverSelect
	 */
	private handleChange = (event: any) => {
		const checkedIds = Array.from(this.domEl.querySelectorAll("input"))
			.filter(cBox => cBox.checked)
			.map(cBox => parseInt(cBox.value, 10));

		logger.log("[PopoverSelect] checkedIds:", checkedIds, Array.from(this.domEl.querySelectorAll("input")));

		this.props.setSelected(checkedIds);
	}

	/**
	 * handle click on the clear button
	 *
	 * @private
	 * @memberof PopoverSelect
	 */
	private handleClear = () => {
		Array
			.from(this.domEl.querySelectorAll("input"))
			.forEach(cBox => {
				cBox.checked = false;
			});

		this.props.setSelected([]);
	}
}
