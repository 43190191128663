import { HttpError } from "../errors";
import stores from "../../stores";
const { config } = stores;
// ************************************
// API HELPERS
// ************************************

export function buildUrl(path: string = "/") {
	return `${ config.apiUrl }${ path }`;
}

export function buildInit(init: RequestInit) {
	return {
		credentials: "same-origin",
		...init
	} as RequestInit;
}

export function buildJSONHeaders(headers: any = {}) {
	return {
		"Content-Type": "application/json",
		...headers,
	};
}

export function checkStatus(response: Response) {
	if (response.ok) {
		return response;
	} else {
		throw new HttpError(response);
	}
}

export function parseJSON<T = any>(resp: Response) {
	return resp.json() as Promise<T>;
}

async function checkAndParse<T>(response: Response): Promise<T> {
	// check the response status before parsing
	const checked = checkStatus(response);
	return await parseJSON<T>(checked);
}

export async function postJSON<T>(url: string, json: any) {
	const response = await fetch(url, buildInit({
		method: "POST",
		headers: buildJSONHeaders(),
		body: JSON.stringify(json),
	}));

	return await checkAndParse<T>(response);
}

export async function putJSON<T>(url: string, json: any) {
	const response = await fetch(url, buildInit({
		method: "PUT",
		headers: buildJSONHeaders(),
		body: JSON.stringify(json),
	}));

	return await checkAndParse<T>(response);
}

export async function getJSON<T>(url: string) {
	const response = await fetch(url, buildInit({
		method: "GET",
	}));

	return await checkAndParse<T>(response);
}

export async function deleteRequest<T>(url: string) {
	const response = await fetch(url, buildInit({
		method: "DELETE",
	}));

	return await checkAndParse<T>(response);
}

export async function getCaptcha<T>() {
	const response = await fetch("https://www.webmart.de/captcha/create");

	return await checkAndParse<T>(response);
}
