import * as React from "react";
import { observer } from "mobx-react";
import PopoverLanguage from "./PopoverLanguage";

import stores from "../../stores";
import { logout } from "../../lib/actions/lifecycle";
import { text } from "../../lib/i18n";

const logo = require("../../images/wmlogo.png");

interface INavbarProps {

}

@observer
export default class Navbar extends React.Component<INavbarProps, {}> {
	public render() {
		return (
			<nav className="topbar navbar navbar-light bg-light">
				<div className="navbar-brand" >
					<a href="/">
						<img
							className="d-inline-block align-top mr-3"
							src={logo}
						/>
					</a>
					<span className="align-middle">TimeTracking</span>
				</div>

				<div className="ml-auto d-flex align-items-center">
					<PopoverLanguage />
					{stores.data && stores.data.selectedOrg && (
						<div className="pl-3 mr-3">
							{stores.data.selectedOrg.name}
						</div>
					)}
				</div>

				{stores.data && !stores.isOnLoginPage && (
					<i
						className="fa fa-sign-out"
						aria-hidden="true"
						title={text("navbar.logout")}
						onClick={logout}
					/>
				)}
			</nav>
		);
	}
}
