import * as Api from "./helpers";
import * as logger from "../../lib/logger";
import {
	ILoginResponse,
	IPollResponse,
	IFilter,
	IGroupedFilter,
	IGroupedMonth,
	IReportUrlData,
	ILogoutData,
} from "../interfaces";
import { DataModel, IDataModel } from "../../stores/models/Data";
import { ITimeEntryModel } from "../../stores/models/TimeEntry";

// ************************************
// API FUNCTIONS
// ************************************

/**
 * Start the login procedure for a given user email.
 * The supplied `email`-adress will receive an e-mail with a magic-link.
 * The returned Promise will resolve to a `polltoken` that you can use to fetch the magic-link click-state.
 *
 *
 * @export
 * @param {string} email user email adress
 * @param {string} response recaptcha response token
 * @returns {Promise<string>} polltoken for usage in `pollLoginStatus`
 */
export async function login(email: string, text: string, token: string) {
	const url = Api.buildUrl("/orgs/login");

	return await Api.postJSON<ILoginResponse>(url, {
		id: 0, // oid 0 means: external user
		email,
		text,
		token,
	});
}

/**
 * Poll the api for the login-state of a polltoken.
 * When the api responds with "pending" you should try again later.
 * When the api responds with "ok" the cookie will be set by the server and we are logged in.
 *
 * Error 404 means - there is no such polltoken.
 *
 * @export
 * @param {string} polltoken token from `login` method
 * @returns {Promise<"pending>|"ok">} poll result
 */
export async function pollLoginStatus(polltoken: string) {
	const url = Api.buildUrl(`/orgs/poll/${ polltoken }`);

	const response = await Api.getJSON<IPollResponse>(url);
	return response.result;
}

/**
 * Fetch the homedata endpoint and turn the response into
 * a living mobx-state-tree
 *
 * @export
 * @returns {Promise<IDataModel>} homedata state-tree
 */
export async function getObsHomeData() {
	const url = Api.buildUrl("/exusers/home");
	const data = await Api.getJSON<IDataModel>(url);
	logger.log("homedata:", JSON.stringify(data, null, 2));
	return DataModel.create(data);
}

export async function getTimes(filter: IFilter) {
	const url = Api.buildUrl(`/exusers/times/filter/${filter.oid}`);
	const data = await Api.postJSON<ITimeEntryModel[]>(url, filter);
	return data;
}

export async function getAvailableMonths(filter: IGroupedFilter) {
	const url = Api.buildUrl(`/exusers/times/filtergrouped/${filter.oid}`);
	const data = await Api.postJSON<IGroupedMonth[]>(url, filter);
	return data;
}

export async function getReportUrl(filter: IFilter) {
	const url = Api.buildUrl(`/exusers/times/report/${filter.oid}`);
	const data = await Api.postJSON<IReportUrlData>(url, filter);
	return data;
}

export async function logout() {
	const url = Api.buildUrl(`/logout/0`); // second param is the oid
	const data = await Api.deleteRequest<ILogoutData>(url);
	return data;
}
