import * as React from "react";

const _CalHeatMap = require("cal-heatmap");
const HeatMapModule: CalHeatMap.CalHeatMapStatic = _CalHeatMap;
import * as moment from "moment";

import * as Utils from "../../../lib/utils";
import * as logger from "../../../lib/logger";

interface IHeatMapProps {
	data: { [ts: number]: number};
}

export default class HeatMap extends React.Component<IHeatMapProps, {}> {
	private static legendColors = {
		min: "#D8E4EE",
		max: "#3D78AB",
		base: "#eee",
		empty: "#eee",
	};

	private domEl: HTMLDivElement;
	private chart: CalHeatMap.CalHeatMap;

	public componentDidMount() {
		const { data } = this.props;

		const chart = new HeatMapModule();
		this.chart = chart;
		chart.init({
			itemSelector: this.domEl,
			domain: "month",
			subDomain: "day",
			range: 3,
			cellSize: 25,
			start: new Date(new Date().getTime() - 61 * 24 * 60 * 60 * 1000), // 3 months ago
			data: data,
			considerMissingDataAsZero: true,
			displayLegend: false,
			legend: this.getLegendTresholds(this.props),
			legendColors: HeatMap.legendColors,
			tooltip: true, // tooltip needs the css
			weekStartOnMonday: false,
			animationDuration: 0,
			domainLabelFormat: (date: Date) => Utils.getMonthFromDate(date), // format month labels
			subDomainTitleFormat: {
				empty: "{date}",
				filled: "{date}&nbsp;-&nbsp;{count}h",
			},
			subDomainDateFormat: (date: Date) => moment(date).locale("de").format("dd, ll"),
		});
	}

	public componentWillUnmount() {
		this.chart = this.chart.destroy();
	}

	public componentWillReceiveProps(nextProps: IHeatMapProps) {
		// TODO: update the chart IF THE DATA DIFFERS
		logger.warn("HeatMap received new props - updating without check");
		this.chart.setLegend(this.getLegendTresholds(nextProps), HeatMap.legendColors);
		this.chart.update(nextProps.data);
	}

	public render() {
		return (
			<div ref={domEl => domEl && (this.domEl = domEl)}/>
		);
	}

	/**
	 * calculates an array of color threshold values used for coloring the heatmap tiles
	 * eg [1, 11, 21, 31]
	 *
	 * @private
	 * @param {IHeatMapProps} { data }
	 * @returns {[number, number, number, number]}
	 * @memberof HeatMap
	 */
	private getLegendTresholds({ data }: IHeatMapProps): [number, number, number, number] {
		let max: number = 0;

		// find max value
		for (const day in data) {
			if (data.hasOwnProperty(day)) {
				const value = data[day];
				if (value > max) {
					max = value;
				}
			}
		}

		// treshold is (max-1)/4
		const treshold = (max - 1) / 4;

		return [1, treshold, treshold * 2, treshold * 3] as [number, number, number, number];
	}
}
