import { types, IModelType } from "mobx-state-tree";
import { Org, IOrg } from "./Org";
import { ITimeEntry } from "./TimeEntry";
import { IFilter } from "./Filter";

export interface IRole {
	id: number;
	billable: boolean;
	hidden: boolean;
	hourly_rate: null|number;
	name: string;
	oid: IOrg;
	secondsByFilter: (filter: IFilter, cid?: number) => number;
}

export const Role: IModelType<any, any> = types.model("Role", {
	id: types.identifierNumber,
	billable: types.boolean,
	hidden: types.boolean,
	hourly_rate: types.union(types.null, types.number),
	name: types.string,
	oid: types.late(() => types.reference(Org)),
}).views(self => ({
	get times() {
		return (self.oid.times as ITimeEntry[]).filter((time: ITimeEntry) => time.rid === self);
	},
})).views(self => ({
	timesByFilter(filter: IFilter) {
		return filter.filterTimes(self.times);
	},
})).views(self => ({
	secondsByFilter(filter: IFilter): number {
		return self
			.timesByFilter(filter)
			.reduce((acc, time) => acc + time.d, 0);
	},
}));

// maybe this works in the future?
// self referencing types and type-inferring don't work well together =(
export type IRoleModel = typeof Role.Type;
